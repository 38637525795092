import { useEffect, useState } from 'react'

export const useAndCleanURLParam = <T extends unknown>(
  name: string,
  parseParam: (raw: string) => T
) => {
  const [state, setState] = useState<T>()
  useEffect(() => {
    const currentURL = new URL(window.location.href)
    const stateParam = currentURL.searchParams.get('state')
    if (stateParam) {
      setState(parseParam(stateParam))
      currentURL.searchParams.delete(name)
    }
    window.history.replaceState({}, document.title, currentURL)
  }, [])
  return state
}

export const getAppAbsoluteURL = (appRoute: string) => {
  const url = new URL(window.location.href)
  url.hash = appRoute
  url.pathname = '/'
  url.search = ''
  return url.href
}
