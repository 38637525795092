import { Chip } from '@mui/material'
import React, { CSSProperties, useRef } from 'react'
import { useDragDropManager, useDragLayer, XYCoord } from 'react-dnd'
import { useSpring, animated } from 'react-spring'
import { usePreviousDifferent } from 'rooks'

import { colorsByType } from './colors'
import { Account } from './data/accounts'
import { Entry } from './data/entries'
import { Tag } from './data/tags'
import { makeStyles } from './utils/makeStyles'

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
}

const globalDragTypes = ['account', 'tag', 'entries'] as const

/** Things that can be drag and dropped from table to tree for example */
type GlobalDragType = (typeof globalDragTypes)[number]
type DragItemType = Account | Tag | DragEntries

type DragEntries = { account: Account; entries: Entry[] }
const useDragItemStyles = makeStyles()({
  expense: {
    '&.MuiChip-root': {
      color: 'white',
      backgroundColor: colorsByType.Expense
    }
  },
  income: {
    '&.MuiChip-root': {
      color: 'white',
      backgroundColor: colorsByType.Income
    }
  },
  tag: {
    '&.MuiChip-root': {
      color: 'white',
      backgroundColor: 'rebeccapurple'
    }
  },
  default: {
    '&.MuiChip-root': {
      color: 'white',
      backgroundColor: colorsByType.Bank
    }
  }
})
const DragItem = ({
  item,
  itemType
}: {
  item: DragItemType
  itemType: GlobalDragType
}) => {
  const { classes } = useDragItemStyles()
  switch (itemType) {
    case 'account': {
      const account = item as Account
      const { type, name } = account
      return (
        <Chip
          className={
            type === 'Expense'
              ? classes.expense
              : type === 'Income'
              ? classes.income
              : classes.default
          }
          label={name}
        />
      )
    }
    case 'entries': {
      // return null
      const { entries } = item as DragEntries
      return (
        <Chip
          className={classes.default}
          label={`${entries.length} opérations`}
        />
      )
    }
    case 'tag': {
      const tag = item as Tag
      return <Chip className={classes.tag} label={tag.name} />
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _check: never = itemType
      return null
    }
  }
}

export default function DragLayer() {
  const [styles, api] = useSpring(() => ({
    opacity: 0,
    scaleX: 0,
    scaleY: 0,
    x: 10,
    y: 10,
    transformOrigin: '0 0',
    display: 'inline-block'
  }))
  const prevItemTypeRef = useRef<string | symbol | null>()
  const prevInitialClientOffset = useRef<XYCoord | null>()
  const prevClientOffset = useRef<XYCoord | null>()
  const dragManager = useDragDropManager()

  const { item, itemType } = useDragLayer((monitor) => {
    const curItemType = monitor.getItemType()
    const clientOffset = monitor.getClientOffset()
    const clientInitialOffset = monitor.getInitialClientOffset()

    // Start
    if (!prevItemTypeRef.current && curItemType && clientOffset) {
      api.start({
        config: {
          tension: 500,
          friction: 25
        }
      })
      api.set({
        x: clientOffset.x,
        y: clientOffset.y
      })
    }

    // Update
    if (curItemType && clientOffset) {
      api.start({
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        x: clientOffset.x,
        y: clientOffset.y
      })
    }

    // Exit
    else if (
      !clientOffset &&
      prevClientOffset.current &&
      prevInitialClientOffset.current
    ) {
      const didDrop = dragManager.getMonitor().didDrop()
      const dest = didDrop
        ? prevClientOffset.current
        : prevInitialClientOffset.current
      api.start({
        opacity: 0,
        x: dest.x,
        y: dest.y,
        scaleX: didDrop ? 0 : 1,
        scaleY: didDrop ? 0 : 1,
        config: {
          tension: 100,
          friction: 20
        }
      })
    }

    prevItemTypeRef.current = curItemType
    prevInitialClientOffset.current = clientInitialOffset
    prevClientOffset.current = clientOffset
    return {
      item: monitor.getItem(),
      itemType: curItemType,
      isDragging: monitor.isDragging()
    }
  })

  // Is used for chip to still show the previous content
  // during exit animation
  const prevItem = usePreviousDifferent(item)
  const prevItemType = usePreviousDifferent(itemType)

  return (
    <span style={layerStyles}>
      <animated.div style={styles}>
        <DragItem
          item={item || prevItem}
          itemType={(prevItemType || itemType) as GlobalDragType}
        />
      </animated.div>
    </span>
  )
}
