import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { del, post } from 'src/client'

import { useMainChart } from './charts'

type ImportResult = string

export const useImportDemoData = ({
  onSuccess
}: {
  onSuccess?: (data: ImportResult) => void
} = {}) => {
  const { data: mainChart } = useMainChart({ enabled: false })

  const client = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const importMutation = useMutation(async () => {
    if (!mainChart) {
      return
    }
    try {
      const data = await post<ImportResult>(
        `/charts/${mainChart.account_id}/import_onboarding_data`
      )

      const chartId = mainChart.account_id
      client.invalidateQueries([`/charts/${chartId}/accounts`])
      client.invalidateQueries([`/charts/${chartId}/tags`])
      client.invalidateQueries(['entries'])
      client.invalidateQueries([`/charts/${chartId}/budgets`])

      enqueueSnackbar('Données de test chargées, bonne utilisation 🚀 !', {
        variant: 'success'
      })
      onSuccess?.(data)
    } catch (e) {
      enqueueSnackbar(
        `Impossible de charger les données de test: ${
          e instanceof Error ? e.message : e
        }`,
        {
          variant: 'error'
        }
      )
    }
  })

  return importMutation
}

type DeleteImportDataResult = string

export const useDeleteDemoData = ({
  onSuccess
}: {
  onSuccess?: (data: DeleteImportDataResult) => void
} = {}) => {
  const client = useQueryClient()
  const { data: mainChart } = useMainChart({ enabled: false })
  const { enqueueSnackbar } = useSnackbar()

  const deleteMutation = useMutation(async () => {
    if (!mainChart) {
      return
    }
    try {
      const data = await del<DeleteImportDataResult>(
        `/charts/${mainChart.account_id}/import_onboarding_data`
      )
      client.invalidateQueries([`/charts/${mainChart.account_id}/accounts`])
      client.invalidateQueries([`/charts/${mainChart.account_id}/tags`])

      onSuccess?.(data)
    } catch (e) {
      enqueueSnackbar(
        `Impossible de supprimer les données de test: ${
          e instanceof Error ? e.message : e
        }`
      )
    }
  })
  return deleteMutation
}
