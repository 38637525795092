import React from 'react'

import flag from './flags'

export default function APIServerStatus() {
  if (flag('api-staging')) {
    return (
      <div
        style={{
          position: 'fixed',
          zIndex: 1000,
          padding: 2,
          left: 0,
          bottom: 0,
          background: 'hotpink',
          fontSize: 'small',
          fontFamily: 'monospace'
        }}
      >
        staging
      </div>
    )
  }
  return null
}
