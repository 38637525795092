import { Box, CircularProgress } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'
import '@mui/styles'
import React from 'react'

import { makeStyles } from 'src/utils/makeStyles'

export type LoadingButtonProps = {
  loading?: boolean
  className?: string
} & ButtonProps

const useStyles = makeStyles()({
  root: {
    position: 'relative'
  },
  spinner: {
    position: 'absolute'
  },
  loadingContent: {
    opacity: 0
  }
})

export default React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (
    {
      loading,
      children,
      className,
      startIcon,
      endIcon,
      disabled,
      ...props
    }: LoadingButtonProps,
    ref
  ) => {
    const { classes, cx } = useStyles()
    return (
      <Button
        ref={ref}
        {...props}
        className={cx(className, classes.root)}
        startIcon={!loading ? startIcon : null}
        endIcon={!loading ? endIcon : null}
        disabled={loading ? true : disabled}
      >
        <Box className={classes.spinner}>
          {loading ? <CircularProgress size={12} color="inherit" /> : null}
        </Box>
        <Box className={loading ? classes.loadingContent : undefined}>
          {children}
        </Box>
      </Button>
    )
  }
)
