import * as Sentry from '@sentry/react'
import {
  QueryClient,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { Account } from 'src/data/accounts'
import * as crisp from 'src/utils/crisp'

export const chartsQuery = (
  options?: UseQueryOptions<Account[], any, Account[]>
): UseQueryOptions<Account[], any, Account[]> => {
  return {
    queryKey: ['/charts'],
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: (failureCount, err) => {
      if (axios.isAxiosError(err) && err?.response?.status === 401) {
        return false
      }
      if (failureCount === 3) {
        return false
      }
      return true
    },
    ...options
  }
}

export const fetchMainChart = async (queryClient: QueryClient) => {
  const [mainChart] = await queryClient.fetchQuery(chartsQuery())
  return mainChart
}

// eslint-disable-next-line
export const useMainChart = (
  options?: UseQueryOptions<Account[], any, Account[]>
): UseQueryResult<Account> => {
  const navigate = useNavigate()

  const query = chartsQuery({
    queryKey: ['/charts'],
    onError: async (err) => {
      const { location } = window
      if (location.hash.startsWith('#/app') && location.hash !== '#/login') {
        navigate(`/login?redirect=${encodeURIComponent(location.hash)}`)
      }
      if (options?.onError) {
        options.onError(err)
      }
    },
    onSuccess: (data) => {
      const chartId = data?.[0].account_id
      crisp.setChartId(chartId)
      if (import.meta.env.MODE === 'production') {
        Sentry.setExtra('chart_id', chartId)
      }
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    }
  })
  const { data: charts, ...rest } = useQuery<Account[], Error>(query)
  if (!charts) {
    return { data: undefined, ...rest } as UseQueryResult<Account>
  }
  return { data: charts[0], ...rest } as UseQueryResult<Account>
}
