const pureSplice = <T extends unknown>(
  arr: T[],
  i: number,
  n: number,
  ...newElems: T[]
) => {
  return [...arr.slice(0, i), ...newElems, ...arr.slice(i + n)]
}

export default pureSplice
