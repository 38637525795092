interface Item {
  children: any[]
}

export type Hierarchical<T> = T & {
  children: T
}

const defaultGetChildren = (item: { children: any[] }) => item.children
const defaultGetId = (item: any) => {
  return item.id
}

const deepKeyBy = <T extends Item>(
  startItems: T[],
  keyFn: (item: T) => string,
  {
    getChildren = defaultGetChildren,
    getId = defaultGetId
  }: {
    getChildren?: (item: T) => T[]
    getId?: (item: T) => string
  } = {}
) => {
  const q = [...startItems]
  const seen: Record<string, boolean> = {}
  const res: Record<string, T> = {}
  while (q.length > 0) {
    const item = q.pop() as T
    const itemId = getId(item)
    if (itemId === undefined) {
      console.log('Item id for ', item, 'is undefined')
      throw new Error('Invalid undefined id')
    }
    seen[getId(item)] = true
    res[keyFn(item)] = item
    const children = getChildren(item)
    if (children) {
      children.forEach((child) => {
        const childId = getId(child)
        if (!seen[childId]) {
          q.push(child)
          seen[childId] = true
        }
      })
    }
  }
  return res
}

export default deepKeyBy
