import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { DialogProps } from '@mui/material/Dialog'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import React, { useCallback } from 'react'

import { makeStyles } from 'src/utils/makeStyles'

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  previousButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },

  withPrevious: {
    paddingLeft: theme.spacing(7)
  },

  withClose: {
    paddingRight: theme.spacing(7)
  }
}))

const EnhancedDialogTitle = ({
  onClose,
  onPrevious,
  children,
  ...dialogTitleProps
}: {
  onClose?: DialogProps['onClose']
  onPrevious?: () => void
} & DialogTitleProps) => {
  const { classes, cx } = useStyles()
  // @ts-ignore
  const handleClose = useCallback((ev) => onClose(ev, 'closeButton'), [])
  return (
    <MuiDialogTitle
      className={cx(
        classes.root,
        onPrevious ? classes.withPrevious : null,
        onClose ? classes.withClose : null
      )}
      {...dialogTitleProps}
    >
      {onPrevious ? (
        <IconButton
          aria-label="close"
          className={classes.previousButton}
          onClick={onPrevious}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
      ) : null}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default EnhancedDialogTitle
