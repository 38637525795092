import { useMutation, useQuery } from '@tanstack/react-query'
import {
  parse,
  subMonths,
  format,
  startOfMonth,
  subDays,
  isBefore,
  addMonths
} from 'date-fns'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'
import set from 'lodash/set'
import sumBy from 'lodash/sumBy'

import client, { fetch, post, put } from 'src/client'
import { useMainChart } from 'src/data/charts'
import assertExhaustive from 'src/utils/assertExhaustive'
import { ensureFloat, ensureInt } from 'src/utils/ensureNumber'

import { Account } from './accounts'
import { WUseQueryOptions } from './common'

export type Budget = {
  budget_id: number
  begin_date: Date
  end_date: Date
}

export type RawBudget = Omit<Budget, 'begin_date' | 'end_date'> & {
  begin_date: string
  end_date: string
}

export interface BudgetRemain {
  month: number
  year: number
  amount_remain: string
}

export interface BudgetEntry {
  account_id: number
  account_name?: string
  outgoing?: boolean
  month: string
  amount_actual: number
  amount_budget: string | number
}

export interface BudgetAccountEntries {
  account_id: number
  entries: BudgetEntry[]
  budget_account_id: number
  outgoing: boolean
}

export interface BudgetAccountResult {
  account_id: number
  amount_budget: number | string
  amount_actual: number | string
  amount_remain: number | string
  entries: BudgetEntry[]
  budget_account_id: number
  certain: boolean
  outgoing: boolean
}

export interface BudgetAccountMonthEntry {
  month: string
  amount_actual: number
  account_id: number
}

export interface BudgetAccountMonthSetting {
  account_id?: number
  amount_budget: string | number
  outgoing: boolean
  month: string
}

export type HorizonMode = '2-month' | '1-month' | 'since-budget-start'

export const DATE_MONTH_FORMAT = 'yyyy-MM-dd'
type DateMonth = string // 'yyyy-mm'

export interface Horizon {
  begin: DateMonth
  end: DateMonth
  mode: HorizonMode
  beginMonth: number // 0 for January
}

type ChangeAccountId = string
type ChangeMonth = string
export type BudgetChanges = Record<
  ChangeAccountId,
  Record<
    ChangeMonth,
    {
      month: string
      amount_budget: string
      outgoing: boolean
      account_id: number
    }
  >
>

const fetchBudgets = async (chartId: Account['account_id']) => {
  const raw = await fetch<RawBudget[]>(`/charts/${chartId}/budgets`)
  return parseBudgets(raw)
}

export const computeBeginOnChangeHorizon = (
  newMode: HorizonMode,
  horizon: Horizon,
  beginMonth: Date
): Horizon['begin'] => {
  const end = parse(horizon.end, DATE_MONTH_FORMAT, new Date())
  switch (newMode) {
    case '2-month':
      return format(subMonths(startOfMonth(end), 1), DATE_MONTH_FORMAT)
    case '1-month':
      return format(startOfMonth(end), DATE_MONTH_FORMAT)
    case 'since-budget-start':
      return format(beginMonth, DATE_MONTH_FORMAT)
    default:
      assertExhaustive(newMode)
  }
}

const parseBudget = (b: RawBudget) => {
  const res = {
    ...b,
    begin_date: parse(b.begin_date, DATE_MONTH_FORMAT, new Date()),
    end_date: parse(b.end_date, DATE_MONTH_FORMAT, new Date())
  } as Budget
  return res
}

const parseBudgets = (l: RawBudget[]) => l.map(parseBudget)

type BudgetQueryOptions = Omit<
  WUseQueryOptions<Budget[], any, Budget[], [string]>,
  'transform'
>

export const budgetsQuery = (
  chartId: number | undefined,
  options?: BudgetQueryOptions
) => {
  return {
    queryKey: [`/charts/${chartId}/budgets`] as [string],
    queryFn: () => fetchBudgets(chartId!),
    enabled: !!chartId,
    ...options
  }
}

export const useBudgets = (options?: BudgetQueryOptions) => {
  const { data: chart } = useMainChart({ enabled: false })
  const chartId = chart?.account_id
  return useQuery(budgetsQuery(chartId, options))
}

export const useBudgetEntries = <T = BudgetAccountEntries[] | undefined>(
  budgetId: number | string,
  options?: WUseQueryOptions<T, any, T>
) => {
  return useQuery<T>([`/budgets/${budgetId}/entries`], options)
}

export const useBudgetAccounts = <T = BudgetAccountResult[] | undefined>(
  budgetId: number,
  horizon: Horizon,
  options?: WUseQueryOptions<T, any, T, [string]>
) => {
  return useQuery({
    queryKey: [
      `/budgets/${budgetId}/accounts?begin_date=${horizon.begin}&end_date=${horizon.end}`
    ],
    ...options
  })
}

export const useBudgetRemainOutgoings = <T = BudgetRemain[] | undefined>(
  budgetId: number,
  options: {
    begin_date?: string
    end_date?: string
  } & WUseQueryOptions<T, any, T>
) => {
  const { begin_date, end_date, ...queryOptions } = options
  const params = new URLSearchParams(
    pickBy({ begin_date, end_date }, Boolean) as Record<string, string>
  )
  return useQuery<T, Error>({
    queryKey: [`/budgets/${budgetId}/remain_outgoings?${params}`],
    ...queryOptions
  })
}

export const getYearFromBudget = (budget: Budget) => {
  return budget.begin_date.getFullYear()
}

type BudgetMonthChange = {
  amount_budget: number
}
type BudgetAccountChange = Record<string, BudgetMonthChange>

export const mergeChangesIntoAccountEntries = (
  accountEntries: BudgetEntry[],
  changes: BudgetAccountChange
): Partial<BudgetEntry>[] => {
  const res = []
  const entriesByMonth = keyBy(accountEntries, (e) => e.month)
  for (let i = 1; i < 13; i++) {
    const month = `${i}`
    const existingEntry = entriesByMonth[`${month}`]
    const monthChange = changes[`${month}`]
    if (existingEntry && monthChange === undefined) {
      res.push({ ...existingEntry })
    } else if (existingEntry && monthChange !== undefined) {
      res.push({
        ...existingEntry,
        amount_budget: `${monthChange.amount_budget}`
      })
    } else if (!existingEntry && monthChange !== undefined) {
      res.push({ month, amount_budget: `${monthChange.amount_budget}` })
    }
  }
  return res
}

export function* fillMissingMonths(
  monthEntries: BudgetEntry[],
  monthIndexes: string[]
) {
  let i = 0
  let n = 0
  while (n < 12) {
    if (monthEntries[i] && monthEntries[i].month === monthIndexes[n]) {
      yield monthEntries[i]
      i++
    } else {
      yield { month: monthIndexes[n], amount_budget: 'NA' }
    }
    n++
  }
}

export const getBudgetDateInfo = (budget?: Budget) => {
  if (!budget) {
    return {
      beginDate: undefined,
      endDate: undefined,
      monthNumbers: Array(12)
        .fill(null)
        .map((_, i) => `${i}`)
    }
  }
  const beginDate = new Date(budget.begin_date)
  const endDate = new Date(budget.end_date)
  const monthIndexes = []
  for (let i = beginDate; isBefore(i, endDate); i = addMonths(i, 1)) {
    monthIndexes.push(`${i.getMonth() + 1}`)
  }
  return { beginDate, endDate, monthIndexes }
}

export const mergeChangesIntoBudgetAccounts = (
  budgetAccounts: BudgetAccountEntries[],
  changes: Record<string, BudgetAccountChange>
) => {
  const byAccountId = keyBy(budgetAccounts, (x) => x.account_id)
  Object.entries(changes).forEach(([accountId, accountChanges]) => {
    const accountBudgetEntries = byAccountId[accountId]
      ? byAccountId[accountId].entries
      : []
    const newAccountEntries = mergeChangesIntoAccountEntries(
      accountBudgetEntries,
      accountChanges
    )
    byAccountId[accountId] = {
      ...byAccountId[accountId],
      account_id: parseInt(accountId, 10),
      // @ts-ignore
      entries: newAccountEntries.map((ae) => {
        if (ae.amount_budget === '0' || ae.amount_budget === 0) {
          return {
            ...ae,
            // '0' does not work here, 0 is correct to remove the entry from the budget
            amount_budget: 0
          }
        }
        return ae
      })
    }
  })
  return Object.values(byAccountId)
}

export const updateBudgetEntries = (
  budgetId: Budget['budget_id'],
  updatedBudgetEntries: BudgetEntry[]
) => {
  return put(`/budgets/${budgetId}/entries`, { entries: updatedBudgetEntries })
}

// TODO replace number by Budget["budget_id"], there is a parse error, maybe because
// of eslint
export const createBudget = async (
  chartId: number,
  year: number
): Promise<number> => {
  const budgetId = await post<string>(`/charts/${chartId}/budgets`, { year })
  return parseInt(budgetId, 10)
}

export const useUpdateBudget = (budgetId: Budget['budget_id'] | undefined) => {
  const { data: chart } = useMainChart({ enabled: false })
  return useMutation(
    async ({ begin_date, end_date }: { begin_date: Date; end_date: Date }) => {
      if (!budgetId) {
        return
      }
      await put(`/charts/${chart?.account_id}/budgets/${budgetId}`, {
        begin_date: format(begin_date, DATE_MONTH_FORMAT),
        end_date: format(end_date, DATE_MONTH_FORMAT)
      })
    },
    {
      onSuccess: () => {
        client.invalidateQueries([`/charts/${chart?.account_id}/budgets`])
        client.invalidateQueries([`/budgets/${budgetId}/entries`])
      }
    }
  )
}

export const useUpdateBudgetEntries = () => {
  const mutation = useMutation(
    async ({
      budgetId,
      entries
    }: {
      budgetId: number
      entries: BudgetEntry[]
    }) => {
      await updateBudgetEntries(budgetId, entries)
    }
  )
  return mutation
}

export const useCreateBudget = () => {
  const { data: chart } = useMainChart()
  const chartId = chart && chart.account_id

  const mutation = useMutation(async ({ year }: { year: number }) => {
    if (!chartId) {
      throw new Error('Could not create budget: no chart id')
    }
    return createBudget(chartId, year)
  })
  return mutation
}

// eslint-disable-next-line import/prefer-default-export
export const synthesisSum = (
  budgetEntries: Pick<
    BudgetAccountResult,
    'amount_actual' | 'amount_budget' | 'amount_remain'
  >[]
) => {
  const sum = budgetEntries.reduce(
    (acc, item) => ({
      amount_actual:
        ensureFloat(acc.amount_actual) + ensureFloat(item.amount_actual),
      amount_budget:
        ensureFloat(acc.amount_budget) + ensureFloat(item.amount_budget),
      amount_remain:
        ensureFloat(acc.amount_remain) + ensureFloat(item.amount_remain)
    }),
    {
      amount_actual: 0,
      amount_budget: 0,
      amount_remain: 0
    }
  ) as Record<string, number>
  return {
    amount_actual: parseFloat(sum.amount_actual.toFixed(2)),
    amount_budget: parseFloat(sum.amount_budget.toFixed(2)),
    amount_remain: parseFloat(sum.amount_remain.toFixed(2))
  }
}

export const invertSum = (
  s: Pick<
    BudgetAccountResult,
    'amount_actual' | 'amount_budget' | 'amount_remain'
  >
) => {
  return {
    amount_actual: -s.amount_actual,
    amount_budget: -s.amount_budget,
    amount_remain: -s.amount_remain
  }
}

export const computeBeginEndDates = (budget: Budget, beginMonth: number) => {
  const currentYear = budget.begin_date.getFullYear()
  const beginDate = new Date(currentYear, beginMonth)
  const endDate = subDays(new Date(currentYear + 1, beginMonth), 1)
  return { beginDate, endDate }
}

/**
 * Used while updating a budget to compute the synthesis of the budget
 * considering the current changes
 */
export const computeEditingBudgetSynthesis = ({
  budgetEntries,
  changes
}: {
  budgetEntries: BudgetAccountEntries[]
  changes: BudgetChanges
}): {
  incoming: number
  outgoing: number
  total: number
} => {
  const outgoingEntries: BudgetAccountMonthSetting[] = []
  const incomingEntries: BudgetAccountMonthSetting[] = []
  const used = {}
  if (budgetEntries) {
    budgetEntries.forEach((be) => {
      const dest = be.outgoing ? outgoingEntries : incomingEntries
      be.entries.forEach((e) => {
        const changePath = [be.account_id.toString(), e.month]
        const change = get(changes, changePath, {})
        set(used, changePath, !!change)
        dest.push({
          ...e,
          ...change
        })
      })
    })
  }
  Object.keys(changes).forEach((accountId) => {
    Object.keys(changes[accountId]).forEach((month) => {
      const hasBeenUsed = get(used, [accountId, month])
      const change = changes[accountId][month]
      if (!hasBeenUsed) {
        const dest = change.outgoing ? outgoingEntries : incomingEntries
        dest.push(change)
      }
    })
  })

  const outgoing = sumBy(outgoingEntries, (x) => ensureInt(x.amount_budget))
  const incoming = sumBy(incomingEntries, (x) => ensureInt(x.amount_budget))
  return { outgoing, incoming, total: incoming - outgoing }
}
