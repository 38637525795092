import { Account } from './data/accounts'

export const incomeColor = '#74BB1B'
export const expenseColor = '#1B8ABB'
export const cashColor = '#DEB83A'
export const bankColor = '#4D8E71'

export const colorsByType: Record<Account['type'], string> = {
  Income: incomeColor,
  Expense: expenseColor,
  Asset: incomeColor,
  Liability: expenseColor,
  Bank: bankColor,
  Checking: bankColor,
  Cash: cashColor,
  Orphan: bankColor
}

export const positiveAmount = '#008400'
export const negativeAmount = '#8e0e01'
