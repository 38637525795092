import {
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import isBefore from 'date-fns/isBefore'

import { del, fetch, post, put } from 'src/client'
import { Account } from 'src/data/accounts'
import { useMainChart } from 'src/data/charts'
import flag from 'src/flags'

export type Currency = 'EUR' | 'USD' | 'CHF'

// TODO share with autofeed
export type BackendAccountStatus =
  | 'Account_Not_Found'
  | 'Do_Not_Import'
  | 'Download_KO'
  | 'Error'
  | 'Imported'
  | 'Import_KO'
  | 'Ready_For_Import'
  | 'Waiting_For_Mapping'

export type BackendStatus =
  | 'Ready_For_Import'
  | 'Connexion_OK'
  | 'Connexion_KO'
  | 'Running'
  | 'Triggered_By_User'

export type BackendAccount = {
  backend_account_id: number
  // Some accounts in some cases have no balance nor currency
  // For example aurelien has two accounts like this on capaccess.chirouze@gmail.com
  bank_account_balance?: string
  bank_account_currency?: Currency
  bank_account_id?: string
  bank_account_label: string
  is_enabled: boolean
  status: BackendAccountStatus
  use_real_date: boolean
  winancial_account_id: number
}

export type Backend = {
  backend_id: number
  backend_name: string
  bank_name: string
  chart_id: number
  error?: string
  bank_login: string
  /** for BI backends, this is the split date */
  bank_login2: string
  bank_password: string
  is_available: boolean
  is_enabled: boolean
  last_running_date: string
  next_running_date: string
  status: BackendStatus
  triggered_by_user: boolean
  accounts: BackendAccount[]
}

export const isOldBackend = (backend: Backend) => {
  const isUsingBridge = flag('bridge-backend')
  return (
    backend.bank_name !== 'bridge' &&
    backend.bank_name !== 'tmp-bridge' &&
    (isUsingBridge ||
      (backend.bank_name !== 'budget-insight' &&
        backend.bank_name !== 'budget-insight-sandbox'))
  )
}

export const findAccountBackendInfo = (
  backends?: Backend[],
  account?: Account
) => {
  if (!backends) {
    return { backend: undefined, backendAccount: undefined }
  }
  for (let i = 0; i < backends?.length; i++) {
    const backend = backends[i]
    const backendAccounts = backends[i].accounts
    for (let j = 0; j < backendAccounts.length; j++) {
      const backendAccount = backendAccounts[j]
      if (backendAccount.winancial_account_id === account?.account_id) {
        return { backend, backendAccount }
      }
    }
  }
  return { backend: undefined, backendAccount: undefined }
}

export const createBackend = async (
  chartAccountId: Account['account_id'],
  {
    bank_login,
    bank_password,
    bank_name
  }: Pick<Backend, 'bank_login' | 'bank_password' | 'bank_name'>
): Promise<Backend['backend_id']> => {
  const res = await post<string>(`/charts/${chartAccountId}/backends`, {
    bank_login,
    bank_login2: '',
    bank_password,
    bank_name
  })
  return parseInt(res, 10)
}

export const backendsQuery = (chartId: Account['account_id']) => {
  return `/charts/${chartId}/backends`
}

export const fetchBackends = async (
  chartId: Account['account_id']
): Promise<Backend[]> => fetch(backendsQuery(chartId))

export const fetchBackend = async (
  chartId: Account['account_id'],
  backendId: Backend['backend_id']
) => {
  const backends = await fetchBackends(chartId)
  return backends.find((b) => b.backend_id === backendId)
}

export const useBackends = (
  queryOptions?: UseQueryOptions<Backend[], unknown, Backend[]>
) => {
  const { data: chart } = useMainChart()
  const chartId = chart?.account_id
  return useQuery<Backend[]>({
    queryKey: ['backends'],
    queryFn: () => fetchBackends(chartId as Account['account_id']),
    enabled: !!chartId,
    ...queryOptions
  }) as UseQueryResult<Backend[]>
}

export const refreshBackends = (chartId: Account['account_id']) => {
  return put(`/charts/${chartId}/backends`)
}

export const refreshBackend = async (chartId: number, backendId: number) => {
  await put(`/charts/${chartId}/backends/${backendId}/sync`)
}

export const isSyncing = (backend: Backend) => {
  const now = new Date()
  const nextRunningDate = new Date(backend.next_running_date)
  return isBefore(nextRunningDate, now)
}

export const deleteBackend = (
  chartId: Account['account_id'],
  backendId: Backend['backend_id']
) => {
  return del(`/charts/${chartId}/backends/${backendId}`)
}

export const saveBackend = (backend: Backend) => {
  return put(
    `/charts/${backend.chart_id}/backends/${backend.backend_id}`,
    backend
  )
}

export const isBridgeBackend = (backend: Backend) => {
  return backend.bank_name === 'bridge'
}

export const isBudgetInsightBackend = (backend: Backend) => {
  return backend.bank_name === 'budget-insight'
}

export const TO_DELETE_PREFIX = `A supprimer : `

export const ensureNoToDeletePrefix = (
  backendName: Backend['backend_name']
) => {
  return backendName.replace(TO_DELETE_PREFIX, '')
}

export const hasBeenRenamedForDeletion = (backend: Backend) => {
  return backend.backend_name.includes(TO_DELETE_PREFIX)
}

export const renameOldConnection = async (
  chartId: Account['account_id'],
  oldBackendId: Backend['backend_id']
) => {
  const oldBackend = await fetchBackend(chartId, oldBackendId)
  if (!oldBackend) {
    return
  }
  if (oldBackend.backend_name.includes(TO_DELETE_PREFIX)) {
    return
  }
  await saveBackend({
    ...oldBackend,
    is_enabled: false,
    backend_name: `${TO_DELETE_PREFIX}${oldBackend.backend_name}`
  })
}
