import CategoryIcon from '@mui/icons-material/Category'
import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/system'
import React, { HTMLProps, useMemo } from 'react'

import bankImage from 'src/assets/bank_s.svg'
import checkingImage from 'src/assets/checking_s.svg'
import expenseImage from 'src/assets/expense_s.svg'
import folderImage from 'src/assets/folder.svg'
import incomeImage from 'src/assets/income_s.svg'
import liabilityImage from 'src/assets/liability_s.svg'
import { Account } from 'src/data/accounts'
import { makeStyles } from 'src/utils/makeStyles'

import { getBankIcon } from '../utils/bankIcons'

const accountImageByType: Record<Account['type'], React.ReactElement | string> =
  {
    Asset: folderImage,
    Income: incomeImage,
    Expense: expenseImage,
    Checking: checkingImage,
    Bank: bankImage,
    Cash: folderImage,
    Liability: liabilityImage,
    Orphan: <CategoryIcon sx={{ mr: '6px', fontSize: 15 }} />
  }

const useStyles = makeStyles()(() => ({
  accountIcon: {
    width: 20,
    height: 20
  },
  accountIconSmall: {
    width: 10,
    height: 10
  }
}))

type AccountIconProps = {
  account: Account
  className?: string
  size?: 'default' | 'small'
  props?: HTMLProps<HTMLImageElement>
}

const AccountIcon = ({
  account,
  className,
  size,

  // Destructure sx here to avoid passing it to img element
  // It has been already used by the Box component "wrapping"
  // the AccountIcon component
  // @ts-ignore
  sx,

  ...props
}: AccountIconProps) => {
  const image = accountImageByType[account.type]
  const { classes, cx } = useStyles()
  const bankIcon = useMemo(() => {
    return getBankIcon(account.name)
  }, [account])

  if (image) {
    if (typeof image === 'string') {
      return (
        <img
          src={bankIcon || image}
          alt=""
          className={cx(
            classes.accountIcon,
            size === 'small' ? classes.accountIconSmall : null,
            className
          )}
          {...props}
        />
      )
    }
    return image
  }
  return null
}

const WrappedAccountIcon = ({
  sx,
  ...props
}: {
  sx?: SxProps<Theme>
} & AccountIconProps) => <Box component={AccountIcon} sx={sx} {...props} />

export default WrappedAccountIcon
