import { useEffect, useRef } from 'react'

const useIntervalWhen = (fn: () => void, delay: number, cond: boolean) => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  useEffect(() => {
    if (cond) {
      if (intervalRef.current) {
        // @ts-ignore
        clearInterval(intervalRef.current as number)
      }
      intervalRef.current = setInterval(fn, delay)
    } else if (intervalRef.current) {
      // @ts-ignore
      clearInterval(intervalRef.current as number)
    }
  }, [cond, delay])
}

export default useIntervalWhen
