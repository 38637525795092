/* eslint-disable react-refresh/only-export-components */

import { lazy } from 'react'

import ConfigureBackendDialog, {
  ConfigureBackendMappingDialog
} from 'src/components/ConfigureBackendDialog'
import BackendPasswordUpdateDialog, {
  BackendEditDialog
} from 'src/views/settings/SettingsView/BackendPasswordUpdateDialog'

import { makeUseModals } from './components/modal-controller'

const CreateAccountOrTagDialog = lazy(
  () => import('src/components/CreateAccountOrTagDialog')
)
const CreateTransactionDialog = lazy(
  () => import('src/components/CreateTransactionDialog')
)
const ImportTransactionsDialog = lazy(
  () => import('src/components/ImportTransactionsDialog')
)
const KeyboardShortcutsDialog = lazy(
  () => import('src/components/KeyboardShortcutsDialog')
)
const RemoveAccountOrTagDialog = lazy(
  () => import('src/components/RemoveAccountOrTagDialog')
)
const SplitTransactionDialog = lazy(
  () => import('src/components/SplitTransactionDialog')
)
const UpdateAccountOrTagDialog = lazy(
  () => import('src/components/UpdateAccountOrTagDialog')
)
const WelcomeDialog = lazy(() => import('src/components/WelcomeDialog'))
const TOTPDialog = lazy(() => import('src/views/TOTPDialog'))
const AddBackendDialog = lazy(
  () => import('src/views/settings/SettingsView/AddBackendDialog')
)
const EditScheduleDialog = lazy(
  () => import('src/views/settings/SettingsView/EditScheduleDialog')
)
const MigrateBackendsDialog = lazy(
  () => import('src/views/settings/SettingsView/MigrateBackendsDialog')
)

const modals = {
  CreateTransaction: CreateTransactionDialog,
  CreateAccountOrTag: CreateAccountOrTagDialog,
  UpdateAccountOrTag: UpdateAccountOrTagDialog,
  RemoveAccountOrTag: RemoveAccountOrTagDialog,
  ImportTransactions: ImportTransactionsDialog,
  SplitTransaction: SplitTransactionDialog,
  MigrateBackends: MigrateBackendsDialog,
  ConfigureBackend: ConfigureBackendDialog,
  AddBackend: AddBackendDialog,
  BackendEdit: BackendEditDialog,
  BackendPasswordUpdate: BackendPasswordUpdateDialog,
  ConfigureBackendMapping: ConfigureBackendMappingDialog,
  Welcome: WelcomeDialog,
  KeyboardShortcuts: KeyboardShortcutsDialog,
  TOTP: TOTPDialog,
  EditSchedule: EditScheduleDialog
}

export const useModals = makeUseModals(modals)

export type ModalName = keyof typeof modals

export default modals
