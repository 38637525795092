import { Account, isAccount } from 'src/data/accounts'
import { Entry } from 'src/data/entries'
import { isTag, Tag } from 'src/data/tags'
import pureSplice from 'src/utils/pureSplice'

type AccountTab = {
  itemId: Account['account_id']
  type: 'account'
}

type TagTab = {
  itemId: Tag['tag_id']
  type: 'tag'
}

export type SearchTab = {
  type: 'search'

  // Undefined is used for search tab on mobile
  search: string | undefined
  itemId?: never
}

type CategorizationTab = {
  type: 'categorization'
  entryIds: Entry['entry_id'][]
  itemId?: never
  id: string
}

type WelcomeTab = {
  type: 'welcome'
  itemId?: never
}

export const getTabId = (tab: Tab) => {
  if (!tab) {
    return null
  }

  const { type } = tab
  switch (type) {
    case 'account':
      return tab.itemId
    case 'tag':
      return tab.itemId
    case 'search':
      return tab.search
    case 'categorization':
      return tab.id
    case 'welcome':
      return null
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _check: never = type
      return _check
    }
  }
}

const isSearchTab = (item: any): item is SearchTab => item.type === 'search'

export type Tab =
  | AccountTab
  | TagTab
  | SearchTab
  | CategorizationTab
  | WelcomeTab

const makeTabFromItem = (item: Account | Tag | SearchTab) => {
  if (isSearchTab(item)) {
    return item
  }
  const itemId = isAccount(item) ? item.account_id : item.tag_id

  return {
    label: item.name,
    itemId,
    type: isAccount(item) ? 'account' : 'tag'
  } as Tab
}
/**
 * Returns new tabs and current tab index when a new tab is opened
 * If the tab already exists, we move to to it, otherwise we open a new tab
 * just after the current tab
 */
export const computeNewTabs = (
  currentTabs: Tab[],
  currentTabIndex: number,
  item: Account | Tag | SearchTab,
  isMobile: boolean
): { tabIndex: number; tabs: Tab[] } => {
  if (isMobile) {
    // Only allow 1 non-search tab on mobile and one search tab
    const tabFinder = isSearchTab(item)
      ? (s: Tab) => s.type === 'search'
      : (s: Tab) => s.type !== 'search'
    const tabIndex = currentTabs.findIndex(tabFinder)
    if (tabIndex !== -1) {
      const newTabs = pureSplice(
        currentTabs,
        tabIndex,
        1,
        makeTabFromItem(item)
      )
      return { tabIndex, tabs: newTabs }
    } else {
      const newTabs = [...currentTabs, makeTabFromItem(item)]
      return { tabIndex: newTabs.length - 1, tabs: newTabs }
    }
  } else {
    // eslint-disable-next-line no-nested-ternary
    const itemId = isAccount(item)
      ? item.account_id
      : isTag(item)
      ? item.tag_id
      : item.search
    const found = currentTabs.findIndex((t) => getTabId(t) === itemId)
    if (found !== -1) {
      return { tabIndex: found, tabs: currentTabs }
    }
    const nextIndex = currentTabIndex + 1
    const newTab = makeTabFromItem(item)
    const newTabs = pureSplice(
      currentTabs,
      currentTabIndex + 1,
      0,
      newTab as Tab
    )
    return { tabIndex: nextIndex, tabs: newTabs }
  }
}
