import { lazy } from 'react'

/**
 * This is to prevent ChunkLoadErrors that can be thrown if the user has
 * a stale version of the app and the old versions tries to load old chunks.
 * When a new version is deployed, old chunks are no longer available and will
 * 404. In this case, the page will be refreshed. To prevent infinite reload
 * loops, a flag is set in localStorage when the page has been refreshed.
 *  * It is removed when a successful chunk load has been done.
 *
 * @see https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    )
    try {
      try {
        const component = await componentImport()
        window.localStorage.setItem('page-has-been-force-refreshed', 'false')
        return component
      } catch (e) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true')
      }
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true')
        return window.location.reload()
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })

export default lazyWithRetry
