import { Adjust, ChevronLeft } from '@mui/icons-material'
import { Box, Button, Slide, IconButton, ButtonBase } from '@mui/material'
import { groupBy } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { TransitionGroup } from 'react-transition-group'
import createStoreHook from 'zustand'
import createStore from 'zustand/vanilla'

import AccountIcon from 'src/components/AccountIcon'
import AccountOrTagToolbar from 'src/components/AccountOrTagToolbar'
import {
  Account,
  isAccount,
  useAccounts,
  useAccountsById
} from 'src/data/accounts'
import { useMainChart } from 'src/data/charts'
import { isTag, Tag, useTagsById } from 'src/data/tags'
import { makeStyles } from 'src/utils/makeStyles'
import useDelayedInit from 'src/utils/useDelayedInit'
import useEvent from 'src/utils/useEvent'

import AccountTypeTabs from '../AccountTypeTabs'
import { TagIcon } from '../TypeIcon'

import useAccountsTreeState from './useAccountsTreeState'

const useMobileTreeStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
    position: 'relative',
    height: '100%',
    width: 300,
    flexDirection: 'column'
  },
  accountTypeTabs: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`
  },
  levels: {
    flexGrow: 1,
    position: 'relative'
  },
  level: {
    width: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
    padding: `${theme.spacing(2)}px 0px`,
    backgroundColor: theme.palette.background.default,
    overflow: 'scroll'
  },
  treeItem: {
    minHeight: 48,
    display: 'grid',
    alignItems: 'center',
    fontSize: 'inherit',
    gridTemplateColumns: '32px auto min-content 32px',
    gridTemplateAreas: '"icon description action menu"',
    columnGap: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
    width: '100%',
    textAlign: 'start',
    justifyContent: 'inherit'
  },
  treeItemMenu: {
    gridArea: 'menu'
  },
  treeItemIcon: {
    gridArea: 'icon'
  },
  treeItemDescription: {
    gridArea: 'description',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  treeItemAction: {
    gridArea: 'action',
    color: theme.palette.grey[700]
  }
}))
const hasParent = (node: HTMLElement, selector: string) => {
  let cur: HTMLElement | null = node
  while (cur) {
    if (cur.matches(selector)) {
      return true
    }
    cur = cur.parentElement
  }
  return false
}
const MobileTreeItem = ({
  item,
  onSelect,
  onDive
}: {
  item: Account | Tag
  onSelect: () => void
  onDive: () => void
}) => {
  const { classes } = useMobileTreeStyles()
  const handleClickRow = useEvent((ev: React.MouseEvent<HTMLButtonElement>) => {
    if (
      hasParent(ev.target as HTMLDivElement, `.${classes.treeItemMenu}`) ||
      hasParent(ev.target as HTMLDivElement, `.${classes.treeItemAction}`)
    ) {
      return
    }
    if (item.children && item.children.length) {
      onDive()
    } else {
      onSelect()
    }
  })
  const handleClickChevron = useEvent(() => {
    onSelect()
  })
  return (
    <ButtonBase
      disableRipple={false}
      className={classes.treeItem}
      onClick={handleClickRow}
    >
      <div className={classes.treeItemIcon}>
        {isAccount(item) ? (
          <AccountIcon account={item} />
        ) : (
          <TagIcon tag={item} />
        )}
      </div>
      <div className={classes.treeItemDescription}>{item.name}</div>
      <div className={classes.treeItemMenu}>
        <AccountOrTagToolbar item={item} />
      </div>
      <div className={classes.treeItemAction}>
        {item.children && item.children.length > 0 ? (
          <IconButton onClick={handleClickChevron}>
            <Adjust color="inherit" />
          </IconButton>
        ) : null}
      </div>
    </ButtonBase>
  )
}
const Level = React.forwardRef<
  HTMLDivElement,
  {
    style?: any
    children: React.ReactNode
  }
>(({ children, style }, ref) => {
  const { classes } = useMobileTreeStyles()
  return (
    <div ref={ref} style={style} className={classes.level}>
      {children}
    </div>
  )
})

export const getItemId = (item: Account | Tag) => {
  if (isAccount(item)) {
    return item.account_id
  } else if (isTag(item)) {
    return item.tag_id
  }
  throw new Error(
    'Unsupported item, a tag or an account must be passed as child of the tree'
  )
}

export const mobileAccountsTree = createStore<{
  parents: Account['account_id'][]
  setParents: (parents: Account['account_id'][]) => void
}>((set) => ({
  parents: [] as Account['account_id'][],
  setParents: (parents: Account['account_id'][]) => set({ parents })
}))

const useMobileAccountsTree = createStoreHook(mobileAccountsTree)

const MobileAccountsTree = ({
  onSelect
}: {
  onSelect?: (item: Account | Tag) => void
}) => {
  const { data: mainChart } = useMainChart()
  const { tab, setTab, items } = useAccountsTreeState()
  const { parents, setParents } = useMobileAccountsTree()
  const { classes } = useMobileTreeStyles()
  const { data: accounts } = useAccounts()
  const { data: accountsById } = useAccountsById()
  const { data: tagsById } = useTagsById()
  const orphanAccount = accounts?.orphanAccount
  const containerRef = useRef(null)
  const listItems = useMemo(() => {
    return tab === 'Bank'
      ? [...items[0].children, orphanAccount].filter(
          <T extends unknown>(v: T | undefined): v is T => v !== undefined
        )
      : items
  }, [tab, items])

  const itemsByParentId = useMemo(() => {
    const allAccounts = Object.values(accountsById || {}).filter(isAccount)
    const allTags = Object.values(tagsById || {})
    return groupBy([...allAccounts, ...allTags], (x) => x.parent_id)
  }, [accountsById, tagsById])

  const levels = useMemo(() => {
    if (!accountsById || !tagsById) {
      return []
    }
    return parents.map((parentId) => ({
      parent: accountsById[parentId! as unknown as keyof typeof accountsById],
      children: itemsByParentId[parentId] || []
    }))
  }, [parents, accountsById])

  useEffect(() => {
    if (mainChart?.account_id && parents.length === 0) {
      setParents([])
    }
  }, [mainChart])

  const handleDive = useEvent((item: Account | Tag, index: number) => {
    // Do nothing if the item has no children
    if (!item.children || item.children.length === 0) {
      return
    }
    setParents([...parents.slice(0, index + 1), getItemId(item)])
  })

  const handleSelect = useEvent((item: Account | Tag) => {
    onSelect?.(item)
  })

  const handleBack = useEvent((index: number) => {
    setParents([...parents.slice(0, index)])
  })

  const handleChangeTab: React.ComponentProps<
    typeof AccountTypeTabs
  >['onChange'] = useEvent((ev, newTab) => {
    setTab(newTab)
    setParents([])
  })

  // While the menu is opening, we do not want Drawer transitions
  // inside the menu to be active
  const disableTransition = useDelayedInit(true, false, 500)

  return (
    <div className={classes.root} ref={containerRef}>
      <div className={classes.accountTypeTabs}>
        <AccountTypeTabs value={tab} onChange={handleChangeTab} />
      </div>
      <div className={classes.levels}>
        <Level>
          {listItems.map((c) => (
            <MobileTreeItem
              item={c}
              key={isAccount(c) ? c.account_id : c.tag_id}
              onDive={() => handleDive(c, 0)}
              onSelect={() => handleSelect(c)}
            />
          ))}
        </Level>
        <TransitionGroup>
          {levels.map((level, index) => {
            return (
              <Slide
                direction="left"
                timeout={disableTransition ? 0 : undefined}
                container={containerRef.current}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Level>
                  <Box height={48} display="flex" alignItems="center">
                    <Button
                      disableRipple={false}
                      startIcon={<ChevronLeft fontSize="small" />}
                      onClick={() => handleBack(index)}
                    >
                      Revenir
                    </Button>
                  </Box>
                  {level.children.map((c) => (
                    <MobileTreeItem
                      item={c}
                      key={isAccount(c) ? c.account_id : c.tag_id}
                      onDive={() => handleDive(c, index)}
                      onSelect={() => handleSelect(c)}
                    />
                  ))}
                </Level>
              </Slide>
            )
          })}
        </TransitionGroup>
      </div>
    </div>
  )
}
export default MobileAccountsTree
