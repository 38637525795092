import { QueryClient, QueryKey, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { merge } from 'lodash'
import { useCallback } from 'react'

import flag from './flags'
import { reset as resetStores } from './stores/reset'
import * as crisp from './utils/crisp'
import useEvent from './utils/useEvent'

const apiServers = {
  prod: 'https://api.winancial.com',
  staging: 'https://api-staging.winancial.com',
  local: 'http://localhost:6543'
} as const

const getAPIServer = () => {
  if (flag('api-staging')) {
    return apiServers.staging
  } else if (!import.meta.env.MODE || import.meta.env.MODE === 'development') {
    return apiServers.local
  } else {
    return apiServers.prod
  }
}

const baseUrl = getAPIServer()

export const makeAbsolute = (route: string) => `${baseUrl}${route}`

export const fetch = async <T extends unknown = unknown>(route: string) => {
  const source = axios.CancelToken.source()
  const promise = axios
    .get<T>(makeAbsolute(route), {
      withCredentials: true,
      cancelToken: source.token,
      headers: {
        accept: 'application/json'
      }
    })
    .then((resp) => {
      return resp.data
    })
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query')
  }
  return promise
}

const matchError = (str: string) => {
  return /^(?:4|5)\d\d.*?;\s*(.*)/.exec(str)
}

export const handleServerResp = (resp: AxiosResponse<any>) => {
  const error = matchError(resp.data)
  if (error) {
    throw new Error(error[1])
  } else if (resp.data === '400 Bad Request') {
    throw new Error(resp.data)
  } else if (resp.data && resp.data.status === 'error') {
    throw new Error(resp.data.message)
  }
  if (resp.data && resp.data.startsWith && resp.data.startsWith('200 OK;')) {
    return resp.data.substring('200 OK;'.length)
  }
  return resp.data
}

type HTTPVerb = 'PUT' | 'GET' | 'DELETE' | 'POST'

export const makeAPIRouteAbsolute = (route: string) => `${baseUrl}${route}`

// @ts-ignore
window.axios = axios

export const makeMethod =
  (verb: HTTPVerb) =>
  async <T extends unknown>(
    route: string,
    data?: Record<string, any>,
    requestConfig?: AxiosRequestConfig
  ) => {
    const params = new URLSearchParams()
    params.append('_method', verb)
    if (data) {
      Object.keys(data).forEach((key) => {
        const value = data[key]
        const shouldStringify =
          typeof value !== 'string' && typeof value !== 'number'
        params.append(key, shouldStringify ? JSON.stringify(value) : `${value}`)
      })
    }
    return axios
      .post(
        makeAPIRouteAbsolute(route),
        params,
        merge(
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          },
          requestConfig
        )
      )
      .then(handleServerResp) as T
  }

export const put = makeMethod('PUT')
export const post = makeMethod('POST')
export const del = makeMethod('DELETE')

// ----------

export const signin = (
  client: QueryClient,
  credentials: {
    email: string
    password: string
  }
) => {
  client.clear()
  return post('/signin?redirect=false', credentials)
}

export const register = (credentials: {
  email: string
  password: string
  tos: boolean
}) => {
  return post('/register', {
    email: credentials.email,
    password: credentials.password,
    cgu: credentials.tos ? 'agreed' : ''
  })
}

export const useSignin = () => {
  const client = useQueryClient()
  const withClientSignin = useEvent(
    (credentials: Parameters<typeof signin>[1]) => {
      return signin(client, credentials)
    }
  )
  return withClientSignin
}

export const signout = (client: QueryClient) => {
  client.clear()
  localStorage.clear()
  resetStores()
  crisp.logout()
  return axios.get(`${baseUrl}/signout`, {
    withCredentials: true
  })
}

export const useSignout = () => {
  const client = useQueryClient()
  const enhancedSignout = useCallback(() => {
    return signout(client)
  }, [client])
  return enhancedSignout
}

// ----------

const defaultQueryFn = async ({ queryKey }: { queryKey: QueryKey }) => {
  return fetch(queryKey[0] as string)
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: 20 * 1000
    }
  }
})

export default queryClient
