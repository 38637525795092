import { useEffect } from 'react'

import { useUserSetting } from 'src/data/settings'
import generateStableUUID from 'src/utils/stable-uuid'

declare global {
  interface Window {
    $crisp: any[]
    CRISP_WEBSITE_ID: string
    CRISP_TOKEN_ID: string | null
  }
}

const localStorageKey = 'crisp:token'

const generateOrRecoverUUID = async (email: string) => {
  const recovered = localStorage.getItem(localStorageKey)
  if (recovered) {
    return recovered
  } else {
    const generated = await generateStableUUID(email)
    localStorage.setItem(localStorageKey, generated)
    return generated
  }
}

const removeUUID = () => {
  localStorage.removeItem(localStorageKey)
}

export const setup = () => {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = 'fbd224e6-462a-4dbe-a797-8a0e83cb028e'
  window.$crisp.push(['do', 'chat:hide'])

  const d = document
  const s = d.createElement('script')
  s.src = 'https://client.crisp.chat/l.js'
  s.async = true
  d.getElementsByTagName('head')[0].appendChild(s)
}

export const login = async (userId: number, email: string) => {
  const uuid = await generateOrRecoverUUID(`${userId}:${email}`)
  window.CRISP_TOKEN_ID = uuid
  window.$crisp.push(['do', 'session:reset'])
  window.$crisp.push(['set', 'session:data', [[['user-id', userId]]]])
}

export const setChartId = (chartId: number) => {
  window.$crisp.push(['set', 'session:data', [[['chart-id', chartId]]]])
}

export const shareEmailWithCrisp = (email: string) => {
  window.$crisp.push(['set', 'user:email', [email]])
}

export const logout = () => {
  removeUUID()
  window.CRISP_TOKEN_ID = null
  window.$crisp.push(['do', 'session:reset'])
}

export const show = () => {
  window.$crisp.push(['do', 'chat:show'])
}

export const hide = () => {
  window.$crisp.push(['do', 'chat:hide'])
}

export const useCrispVisible = () => {
  const { data: crispEnabled } = useUserSetting('crisp.enabled')
  useEffect(() => {
    if (crispEnabled) {
      show()
    } else {
      hide()
    }
    return () => {
      hide()
    }
  }, [crispEnabled])
}
