import createStoreHook from 'zustand'
import { persist } from 'zustand/middleware'
import createStore from 'zustand/vanilla'

import { AccountNavType } from 'src/components/AccountTypeTabs'
import { getItemId } from 'src/components/TabsAccountsTree/MobileAccountsTree'
import { Account } from 'src/data/accounts'
import { Tag } from 'src/data/tags'
import pureSplice from 'src/utils/pureSplice'
import { computeNewTabs, SearchTab, Tab, getTabId } from 'src/utils/tabs'

export const findTab = (tabs: Tab[], tab: Tab) => {
  const tabId = getTabId(tab)
  return tabs.findIndex((t) => getTabId(t) === tabId)
}

export type DashboardState = {
  tabs: Tab[]
  currentTab: number
  accountsTreeTab: AccountNavType
  setAccountsTreeTab: (newTab: AccountNavType) => void
  setTabs: (tabs: Tab[], newTabIndex?: number) => void
  addTab: (tab: Tab) => void
  setCurrentTabIndex: (n: number) => void
  closeTab: (n: number) => void
  openTab: (
    item: Account | Tag | SearchTab,
    isMobile: boolean,
    first?: boolean
  ) => void
}

export const dashboardStore = createStore<DashboardState>(
  persist(
    (set) => ({
      accountsTreeTab: 'Bank' as AccountNavType,
      setAccountsTreeTab: (newTab: AccountNavType) =>
        set({ accountsTreeTab: newTab }),
      tabs: [] as Tab[],
      currentTab: 0,
      setCurrentTabIndex: (value) => set({ currentTab: value }),
      setTabs: (tabs: Tab[], newTabIndex?: number) => {
        set((state) => {
          const currentTab =
            newTabIndex !== undefined // eslint-disable-line no-nested-ternary
              ? newTabIndex
              : tabs.length > state.tabs.length
              ? tabs.length - 1
              : state.currentTab
          return { tabs, currentTab }
        })
      },
      addTab: (tab: Tab) => {
        return set((state) => {
          const { tabs } = state
          const index = findTab(tabs, tab)
          if (index === -1) {
            const updatedTabs = [...tabs, tab]
            set({ tabs: updatedTabs })
            set({ currentTab: updatedTabs.length - 1 })
          } else {
            set({ currentTab: index })
          }
        })
      },
      openTab: (item: Account | Tag | SearchTab, isMobile: boolean) => {
        return set((state) => {
          const { tabs: currentTabs, currentTab: currentTabIndex } = state
          const computedTabs = computeNewTabs(
            currentTabs,
            currentTabIndex,
            item,
            isMobile
          )
          let { tabs } = computedTabs
          tabs = tabs.filter(Boolean)
          const tabIndex = tabs.findIndex((t) =>
            'search' in item
              ? t.itemId === item.search
              : t.itemId === getItemId(item)
          )
          set({ tabs, currentTab: tabIndex })
        })
      },
      closeTab: (i: number) => {
        return set((state) => {
          const { tabs, currentTab } = state
          const newTabs = pureSplice(tabs, i, 1)
          const newTabIndex = Math.min(
            Math.max(
              i >= currentTab ? currentTab : currentTab - 1,
              newTabs.length > 0 ? 0 : -1
            ),
            newTabs.length - 1
          )
          set({ tabs: newTabs })
          set({ currentTab: newTabIndex })
        })
      }
    }),
    {
      name: 'dashboard',
      deserialize: (str: string) => {
        const storageValue = JSON.parse(str)
        const { state } = storageValue
        const oldTabs = state.tabs
        const newTabs = state.tabs ? state.tabs.filter(Boolean) : undefined
        if (oldTabs.length !== newTabs.length) {
          state.tabs = newTabs
          state.currentTab = Math.max(
            0,
            state.currentTab - (oldTabs.length - newTabs.length)
          )
        }
        return storageValue
      }
    }
  )
)

export const useDashboardStore = createStoreHook(dashboardStore)
