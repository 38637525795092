import { Account } from 'src/data/accounts'
import { Tag } from 'src/data/tags'

// gtag is disabled in development
const globalGtag = typeof window !== 'undefined' ? window.gtag : undefined

const gtag =
  process.env.NODE_ENV === 'production' && globalGtag
    ? globalGtag
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (...args: any[]) => {}

type EventOptions = {
  // Accounts page
  navigate_account: {
    type: Account['type']
    name: Account['name']
  }
  navigate_tag: {
    type: Tag['type']
    name: Tag['name']
  }
  // Synthesis page
  set_synthesis_date: {}
  set_synthesis_cagegory: {}
  set_synthesis_account:
    | undefined
    | {
        type: Account['type']
        name: Account['name']
      }
  set_synthesis_tag:
    | undefined
    | {
        type: Tag['type']
        name: Tag['name']
      }
  vendor_connection_callback: {
    type: 'created' | 'existing'
  }
  update_transaction: {
    type:
      | 'amount'
      | 'name'
      | 'date'
      | 'category'
      | 'tag'
      | 'description'
      | 'memo'
  }
  delete_transaction: {
    count: number
  }
  update_account: {
    type: 'settings' | 'drag_and_drop'
  }
  update_tag: {
    type: 'settings' | 'drag_and_drop'
  }
  // Global
  open_modal: {
    name: string
  }
  close_modal: {
    name?: string
  }
}

/** Use this instead of directly using gtag('event'), to benefit from typing */
export const gtagEvent = <T extends keyof EventOptions>(
  action: T,
  options: EventOptions[T]
) => {
  gtag('event', action, options)
}

export default gtag
