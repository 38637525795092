import createStoreHook from 'zustand'
import createStore from 'zustand/vanilla'

import { Entry } from 'src/data/entries'

export type AutoCategorizationState = {
  state: 'idle' | 'pending' | 'success' | 'error'
  entryIds: Entry['entry_id'][]
  setSuccess: (entryIds: AutoCategorizationState['entryIds']) => void
  setIdle: () => void
  setPending: () => void
}

export const counterStore = createStore<{
  counter: number
  setCounter: (n: number) => void
}>((set) => ({
  counter: 0,
  setCounter: (n) => set({ counter: Math.random() })
}))

export const useCounter = createStoreHook(counterStore)

export const autoCategorizationStore = createStore<AutoCategorizationState>(
  (set) =>
    ({
      entryIds: [],
      state: 'idle',
      setSuccess: (entryIds) => set({ state: 'success', entryIds }),
      setIdle: () => set({ state: 'idle', entryIds: [] }),
      setPending: () => set({ state: 'pending', entryIds: [] })
    } as AutoCategorizationState)
)

export const useAutoCategorizationStore = createStoreHook(
  autoCategorizationStore
)
