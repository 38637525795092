// @ts-ignore We should use StrictEventEmitter to type EventEmitter
import EventEmitter from 'microee'

import lsAdapter from './ls-adapter'

export type FlagValue = any
export type FlagName = string

/**
 * In memory key value storage.
 *
 * Can potentially be backed by localStorage if present

 * Emits `change` when a key is set (eventEmitter)
 */
class FlagStore {
  longtermStore: typeof lsAdapter | null

  store: Record<string, any>

  constructor() {
    this.store = {}
    this.longtermStore = null
    if (typeof localStorage !== 'undefined') {
      this.longtermStore = lsAdapter
    }
    this.restore()
  }

  restore() {
    if (!this.longtermStore) {
      return
    }
    const allValues = this.longtermStore.getAll()
    Object.entries(allValues).forEach(([flag, val]) => {
      this.store[flag] = val
      this.emit('change', flag)
    })
  }

  keys() {
    return Object.keys(this.store)
  }

  get(name: string): FlagValue {
    if (!Object.prototype.hasOwnProperty.call(this.store, name)) {
      this.store[name] = null
    }
    return this.store[name]
  }

  set(name: string, value: FlagValue) {
    if (this.longtermStore) {
      this.longtermStore.setItem(name, value)
    }
    this.store[name] = value
    this.emit('change', name)
  }

  remove(name: string) {
    delete this.store[name]
    if (this.longtermStore) {
      this.longtermStore.removeItem(name)
    }
    this.emit('change', name)
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  emit(_ev: string, _options: any): void {}

  // eslint-disable-next-line class-methods-use-this
  on(_event: string, _fn: (changed: string) => void) {}

  // eslint-disable-next-line class-methods-use-this
  removeListener(_event: string, _fn: (changed: string) => void) {}
}

EventEmitter.mixin(FlagStore)

export default FlagStore
