import { useQuery, QueryClient } from '@tanstack/react-query'
import { parse } from 'date-fns'

import { del, post, put } from 'src/client'
import { CategoryAutocompleteValue } from 'src/components/CategoryAutocomplete'
import { Account } from 'src/data/accounts'

import { DATE_MONTH_FORMAT } from './budgets'
import { useMainChart } from './charts'
import { Entry } from './entries'

export const schedulesQuery = (chartId: number | undefined) => ({
  queryKey: [`/charts/${chartId}/schedules`]
})

export const invalidateSchedules = (
  client: QueryClient,
  chartId: Account['account_id']
) => {
  client.invalidateQueries(schedulesQuery(chartId).queryKey)
}

export const deleteSchedule = async (
  client: QueryClient,
  chartId: number,
  scheduleId: number
) => {
  await del(`/schedules/${scheduleId}`)
  invalidateSchedules(client, chartId)
}

export const toggleSchedule = async (
  client: QueryClient,
  chartId: number,
  schedule: Schedule
) => {
  await put(`/schedules/${schedule.schedule_id}`, {
    active: !schedule.active
  })
  invalidateSchedules(client, chartId)
}

export type ScheduleForm = {
  description: string
  memo: string
  amount: number | undefined
  category_id: CategoryAutocompleteValue | undefined
  account_id: CategoryAutocompleteValue | undefined
  transaction_date: string
  end_date: string
  days_before_transaction_date: number
  type: Account['type']
}

export type RawSchedule = {
  schedule_id: number
  chart_id: number
  active: boolean
  period_nb: number
  transaction_date: string // YYYY-MM-DD
  end_date: string // YYYY-MM-DD
  code: string
  description: string
  memo: string
  entries: [Entry, Entry]
  days_before_transaction_date: number
  tags: Record<string, unknown>
}

export type Schedule = Omit<RawSchedule, 'transaction_date' | 'end_date'> & {
  transaction_date: Date
  end_date: Date | undefined
}

export const parseSchedule = (s: RawSchedule, now = new Date()) => ({
  ...s,
  transaction_date: parse(s.transaction_date, DATE_MONTH_FORMAT, now),
  end_date:
    s.end_date === 'None'
      ? undefined
      : parse(s.end_date, DATE_MONTH_FORMAT, now)
})

export const parseSchedules = (data: RawSchedule[]) => {
  return data.map((x) => parseSchedule(x))
}

export const useSchedules = () => {
  const { data: mainChart } = useMainChart({ enabled: false })
  return useQuery({
    queryKey: [`/charts/${mainChart?.account_id}/schedules`],
    select: parseSchedules
  })
}

export const createOrUpdateSchedule = async (
  chartId: number,
  scheduleId: number | undefined,
  scheduleForm: ScheduleForm
) => {
  const parsedAmount = scheduleForm.amount ?? 0
  return (scheduleId ? put : post)(
    scheduleId ? `/schedules/${scheduleId}` : `/charts/${chartId}/schedules`,
    {
      memo: scheduleForm.memo,
      description: scheduleForm.description,
      transaction_date: scheduleForm.transaction_date,
      days_before_transaction_date: scheduleForm.days_before_transaction_date,
      end_date: scheduleForm.end_date,
      entries: JSON.stringify([
        {
          amount: parsedAmount.toString(),
          account_id: scheduleForm?.category_id?.value,
          is_debit: scheduleForm.type === 'Expense'
        },
        {
          amount: (-parsedAmount).toString(),
          account_id: scheduleForm?.account_id?.value,
          is_debit: scheduleForm.type !== 'Expense'
        }
      ])
    }
  )
}
