import hotkeys from 'hotkeys-js'
import { useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigate } from 'react-router-dom'

import { useModals } from './modals'
import { setScope } from './utils/observable-hotkeys'

const NavigationHotkeys = () => {
  const navigate = useNavigate()

  const navigateTo = useMemo(
    () => (route: string) => () => {
      navigate(route)
      setScope('all')
    },
    [navigate]
  )

  useHotkeys('g', () => {
    setScope('navigation')
    setTimeout(() => {
      if (hotkeys.getScope() === 'navigation') {
        setScope('all')
      }
    }, 2000)
  })

  useHotkeys('c', navigateTo('/app/accounts'), {
    scope: 'navigation'
  })
  useHotkeys('s', navigateTo('/app/synthesis'), {
    scope: 'navigation'
  })
  useHotkeys('b', navigateTo('/app/budget'), {
    scope: 'navigation'
  })
  useHotkeys('p', navigateTo('/app/settings'), {
    scope: 'navigation'
  })

  const { openModal } = useModals()
  useEffect(() => {
    const handle = (ev: KeyboardEvent) => {
      if (ev.key === '?' && ev.target === document.body) {
        openModal('KeyboardShortcuts')
      }
    }
    document.addEventListener('keydown', handle)
    return () => {
      document.removeEventListener('keydown', handle)
    }
  })
  return null
}

export default NavigationHotkeys
