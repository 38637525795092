import { autoCategorizationStore } from './autoCategorization'
import { dashboardStore } from './dashboard'
import { guidedTours } from './guidedTours'
import { selectionStore } from './selection'
import { settingsStore } from './settings'

const ALL_STORES = [
  dashboardStore,
  settingsStore,
  selectionStore,
  guidedTours,
  autoCategorizationStore
] as const

// eslint-disable-next-line import/prefer-default-export
export const reset = () => {
  ALL_STORES.forEach((store) => {
    store.destroy()
    if (Object.prototype.hasOwnProperty.call(store, 'persist')) {
      // @ts-ignore
      store.persist.clearStorage()
      // @ts-ignore
      store.persist.rehydrate()
    }
    store.destroy()
  })
}
