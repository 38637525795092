import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://15b89f156b34464f9bf05d8d6637a965@o30007.ingest.sentry.io/5932237',
    integrations: [new BrowserTracing()],
    release: `winancial-app-${import.meta.env.VITE__GIT_SHA}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}
