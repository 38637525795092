import hotkeys from 'hotkeys-js'
// @ts-ignore StrictEventEmitter is used to type EventEmitter
import EventEmitter from 'microee'
import { useEffect, useState } from 'react'
import StrictEventEmitter from 'strict-event-emitter-types'

interface Events {
  scope: void
}

type HotkeysEventEmitter = StrictEventEmitter<EventEmitter, Events>

export const hotkeysEventEmitter: HotkeysEventEmitter = new EventEmitter()

export const setScope = (scope: string) => {
  hotkeys.setScope(scope)
  hotkeysEventEmitter.emit('scope')
}

export const useHotkeysScope = () => {
  const [scope, setScopeState] = useState(() => hotkeys.getScope())
  useEffect(() => {
    const handle = () => {
      setScopeState(hotkeys.getScope())
    }
    hotkeysEventEmitter.on('scope', handle)
    return () => {
      hotkeysEventEmitter.removeListener('scope', handle)
    }
  }, [])
  return scope
}
