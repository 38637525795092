import UITab, { TabProps } from '@mui/material/Tab'
import Tabs, { TabsProps } from '@mui/material/Tabs'
import { alpha, darken } from '@mui/material/styles'
import { CSSProperties } from '@mui/styles'
import { throttle } from 'lodash'
import React, { useMemo } from 'react'
import { useDrop } from 'react-dnd'

import TypeIcon from 'src/components/TypeIcon'
import { makeStyles, withStyles } from 'src/utils/makeStyles'

const useStyles = makeStyles()((theme) => ({
  root: {
    flexShrink: 0
  },
  tab: {
    minWidth: '4rem',
    margin: 0,
    flexShrink: 0,
    padding: theme.spacing(1),
    '& img': {
      marginBottom: '0.25rem'
    }
  }
}))

const TypeTab = ({
  label,
  value,
  icon,
  selectFromDragAndDropHover,
  ...props
}: {
  label: string
  value: string
  icon: React.ReactElement
  selectFromDragAndDropHover: (tabValue: AccountNavType) => void
} & TabProps) => {
  const { classes } = useStyles()

  const [, drop] = useDrop(() => ({
    accept: ['entries'],
    hover: (item, monitor) => {
      if (!monitor.isOver({ shallow: true })) {
        return
      }
      selectFromDragAndDropHover(value)
    }
  }))
  return (
    <UITab
      disableRipple
      ref={drop}
      className={classes.tab}
      {...props}
      value={value}
      label={
        <>
          {icon}
          {label}
        </>
      }
    />
  )
}

const StyledTabs = withStyles(
  Tabs,
  (theme) => ({
    indicator: {
      background:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.secondary.light, 1)
          : darken(theme.palette.primary.dark, 0.95),
      borderRadius: theme.shape.borderRadius,
      height: '100%',
      display: 'block',
      zIndex: 0,
      position: 'absolute' as CSSProperties['position'],
      top: 0,
      left: 0,
      width: 'auto'
    },
    flexContainer: {
      justifyContent: 'space-between',
      position: 'relative' as CSSProperties['position'],
      zIndex: 1,
      height: 'auto'
    },
    root: {
      flexShrink: 0,
      padding: `0 ${theme.spacing(1)}`
    }
  }),
  { name: 'AccountTypeTabs' }
)

export type AccountNavType = 'Bank' | 'Tags' | 'Expense' | 'Income'

const AccountTypeTabs = ({
  onChange,
  value,
  showAssets,
  showTags,
  orientation
}: {
  onChange: (ev: any, tab: AccountNavType) => void
  value: string
  showAssets?: boolean
  showTags?: boolean
  orientation?: TabsProps['orientation']
}) => {
  const { classes } = useStyles()
  const selectFromDragAndDropHover = useMemo(() => {
    return throttle((tab: AccountNavType) => {
      const event = new Event('change')
      onChange?.(event, tab)
    }, 500)
  }, [])
  return (
    <StyledTabs
      value={value}
      className={classes.root}
      onChange={onChange}
      orientation={orientation}
      scrollButtons={false}
    >
      {showAssets !== false ? (
        <TypeTab
          selectFromDragAndDropHover={selectFromDragAndDropHover}
          value="Bank"
          label="Banques"
          icon={<TypeIcon type="Bank" />}
        />
      ) : null}
      <TypeTab
        selectFromDragAndDropHover={selectFromDragAndDropHover}
        value="Expense"
        label="Dépenses"
        icon={<TypeIcon type="Expense" />}
      />
      <TypeTab
        selectFromDragAndDropHover={selectFromDragAndDropHover}
        value="Income"
        label="Revenus"
        icon={<TypeIcon type="Income" />}
      />
      {showTags !== false ? (
        <TypeTab
          selectFromDragAndDropHover={selectFromDragAndDropHover}
          value="Tags"
          label="Projets"
          icon={<TypeIcon type="Tags" />}
        />
      ) : null}
    </StyledTabs>
  )
}

AccountTypeTabs.defaultProps = {
  showAssets: true,
  showTags: true
}

export default AccountTypeTabs
