import { Breakpoint, Theme, useTheme } from '@mui/material'
import { throttle } from 'lodash'
import React, { useMemo, useEffect, useState, useContext } from 'react'

import { lightTheme } from './theme'
import useEvent from './utils/useEvent'

const isMatchingBreakpoint = (theme: Theme, breakpoint: Breakpoint) => {
  const mq = theme.breakpoints.down(breakpoint)
  const res = window.matchMedia(mq.replace('@media ', '')).matches
  return res
}

const IsMobileContext = React.createContext(
  isMatchingBreakpoint(lightTheme, 'sm')
)

export const IsMobileProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const theme = useTheme()
  const [isMobile, setIsMobile] = useState(() => {
    return isMatchingBreakpoint(theme, 'sm')
  })
  const update = useEvent(() => {
    setIsMobile(isMatchingBreakpoint(theme, 'sm'))
  })
  const handleResize = useMemo(
    () => throttle(update, 16, { trailing: true }),
    [update]
  )
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  })
  return (
    <IsMobileContext.Provider value={isMobile}>
      {children}
    </IsMobileContext.Provider>
  )
}

const useIsMobile = () => useContext(IsMobileContext)

export default useIsMobile
