import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { createHashRouter, RouterProvider } from 'react-router-dom'

import './polyfills'
import routes from './routes'
import * as serviceWorker from './serviceWorker'
import './sentry'
import * as crisp from './utils/crisp'

if (typeof (window as any).global === 'undefined') {
  ;(window as any).global = window
}

const router = createHashRouter(routes)
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Suspense fallback={<></>}>
    <RouterProvider router={router} />
  </Suspense>
)

serviceWorker.unregister()
crisp.setup()
