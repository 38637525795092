import {
  Query,
  QueryClient,
  QueryFunctionContext,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import React, { useContext, useMemo } from 'react'

import { del, fetch, post, put } from 'src/client'
import { useMainChart } from 'src/data/charts'
import deepKeyBy, { Hierarchical } from 'src/utils/deepKeyBy'

import { Currency } from './backends'
import { WUseQueryOptions } from './common'
import { Entry } from './entries'

export type Account = {
  account_id: number
  parent_id: number
  type:
    | 'Asset'
    | 'Liability'
    | 'Income'
    | 'Expense'
    | 'Orphan'
    | 'Bank'
    | 'Checking'
    | 'Cash'
  children: Account[]
  name: string | null
  balance?: number
  open_balance_date?: Date
  open_balance_amount?: number
  currency?: Currency
  description?: string
  is_debit?: boolean
  path?: string
  reconciled_balance?: number
}

export type AccountIdField = 'account_id' | 'opp_acc_id'
export type AccountLabelField = 'account_name' | 'opp_acc_name'

export const isTransferAccount = (item: Account) => {
  return item.type === 'Asset' && item.name === 'Virements internes'
}

type RawAccount = Omit<Account, 'open_balance_date'> & {
  open_balance_date: 'string'
}

export type AccountWithName = Omit<Account, 'name'> & {
  name: NonNullable<Account['name']>
}

export const hasName = (acc: Account): acc is AccountWithName => !!acc.name

export interface AllAccounts {
  orphanAccount: Account
  assetAccounts: Account[]
  liabilityAccounts: Account[]
  incomeAccounts: Account[]
  expenseAccounts: Account[]
  liabilityRoot: Account
  expenseRoot: Account
  incomeRoot: Account
  assetRoot: Account
}

export interface AccountMonthActualBudget {
  month: string
  year: string
  amount_actual: string
}

export type AccountsByMonthKey = [string, number, number]

const getAccountId = (x: Account) => x.account_id

export const fetchChartAccounts = async ({
  queryKey: [route]
}: QueryFunctionContext) => {
  if (typeof route !== 'string') {
    throw new Error('Needs queryKey for fetchChartAccounts')
  }

  const today = format(new Date(), 'yyyy-MM-dd')
  const rawAccounts = await fetch<RawAccount[]>(
    `${route}?begin_date=1980-01-01&end_date=${today}`
  )
  const accounts = rawAccounts.map(parseAccount) as Hierarchical<Account>[]
  const makeKey = (acc: Account) => `${getAccountId(acc)}`
  return deepKeyBy<Hierarchical<Account>>(accounts, makeKey, {
    getId: makeKey
  }) as AccountIndex
}

export const fetchAccountAmountsByMonth = async ({
  queryKey
}: QueryFunctionContext<[unknown, number | undefined, string, string]>) => {
  if (!Array.isArray(queryKey) || queryKey.length !== 4) {
    throw new Error(
      'fetchAccountAmountsByMonth needs queryKey to be an array of [keyName, accountId, beginDate, endDate]'
    )
  }
  const [, accountId, beginDate, endDate] = queryKey
  if (!accountId) {
    throw new Error('Undefined accountId passed to fetchAccountAmountsByMonth')
  }
  const qs = new URLSearchParams({
    begin_date: beginDate,
    end_date: endDate
  })
  const data = await fetch<AccountMonthActualBudget[]>(
    `/accounts/${accountId}/amounts_by_month?${qs}`
  )
  return data
}

export type CreateAccountData = {
  parent_id: Account['account_id']
  type: Account['type']
  isTypeReadWrite: boolean
  description: string
  name: string
}

export type UpdateAccountData = {
  account_id: Account['account_id']
  parent_id: Account['account_id']
  description: string
  name: string
  open_balance_amount?: string
  open_balance_date?: string

  /** Stored in user settings */
  archived?: boolean
}

export const createAccount = async (
  data: CreateAccountData
): Promise<Account['account_id']> => {
  const res = await post<string>(`/charts/${data.parent_id}/accounts`, data)
  return parseInt(res, 10)
}

export const updateAccount = async (account: UpdateAccountData) => {
  const res = await put(`/accounts/${account.account_id}`, account)
  return res
}

export const deleteAccount = (accountId: Account['account_id']) => {
  return del(`/accounts/${accountId}`, {
    include_subtree: true
  })
}

// --------- Hooks ---------
type AccountIndex = Record<string, Account>

export type RootAccounts = {
  orphanAccount?: Account
  assetAccounts?: Account[]
  liabilityAccounts?: Account[]
  incomeAccounts?: Account[]
  expenseAccounts?: Account[]
  liabilityRoot?: Account
  expenseRoot?: Account
  incomeRoot?: Account
  assetRoot?: Account
}

export const useAccounts = (
  options?: WUseQueryOptions<AccountIndex, unknown, AccountIndex>
) => {
  const { data: chart } = useMainChart()
  const chartId = chart && chart.account_id
  const route = `/charts/${chartId}/accounts`
  const { data: accountsById, ...rest } = useQuery<AccountIndex>([route], {
    queryFn: fetchChartAccounts,
    staleTime: 30 * 1000,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!chartId,
    ...options
  })

  const accounts = useMemo(
    () => (accountsById ? Object.values(accountsById) : []),
    [accountsById]
  )

  const assetRoot = accounts && accounts.find((x) => x.type === 'Asset')
  const liabilityRoot = accounts && accounts.find((x) => x.type === 'Liability')
  const incomeRoot = accounts && accounts.find((x) => x.type === 'Income')
  const expenseRoot = accounts && accounts.find((x) => x.type === 'Expense')
  const orphanAccount = accounts && accounts.find((x) => x.type === 'Orphan')

  const data: RootAccounts = accounts
    ? {
        orphanAccount,
        assetAccounts: assetRoot && assetRoot.children,
        liabilityAccounts: liabilityRoot && liabilityRoot.children,
        incomeAccounts: incomeRoot && incomeRoot.children,
        expenseAccounts: expenseRoot && expenseRoot.children,
        liabilityRoot,
        expenseRoot,
        incomeRoot,
        assetRoot
      }
    : {}

  return {
    data,
    ...rest
  }
}

const parseAccount = (acc: RawAccount): Account => {
  return {
    ...acc,
    open_balance_date: acc.open_balance_date
      ? parse(acc.open_balance_date, 'yyyy-MM-dd', new Date())
      : undefined
  }
}

const fetchAccount = async (
  accountId: Account['account_id'],
  options: { withReconciledBalance?: boolean } = {}
) => {
  const params = new URLSearchParams({
    with_reconciled_balance: `${options?.withReconciledBalance ?? false}`
  })
  const accounts = await fetch<RawAccount[]>(`/accounts/${accountId}?${params}`)
  if (!accounts[0]) {
    throw new Error(`Could not find account ${accountId}'`)
  }
  return parseAccount(accounts[0])
}

export const useAccountsById = (
  options?: WUseQueryOptions<AccountIndex, unknown, AccountIndex>
): {
  data: Record<string, Account> | undefined
} => {
  const { data: chart } = useMainChart()
  const chartId = chart && chart.account_id
  return useQuery<AccountIndex, unknown, AccountIndex>({
    queryKey: [`/charts/${chartId!}/accounts`],
    queryFn: fetchChartAccounts,
    enabled: !!chartId,
    ...options
  })
}

export const isDemoAccount = (acc: Account) => {
  return acc.description === 'Compte de démo'
}

export const isCheckingAccount = (acc: Account) => {
  return acc.type === 'Checking'
}

export const isRealEstateCategory = (acc: Account) => {
  return acc.name === 'Immobilier'
}

export const useDemoAccounts = () => {
  const { data: accountsById } = useAccountsById()
  return useMemo(() => {
    if (!accountsById) {
      return undefined
    }
    return Object.values(accountsById).filter(isDemoAccount)
  }, [accountsById])
}

export const useFilteredAccounts = (filter: (acc: Account) => boolean) => {
  const { data: accountsById } = useAccountsById()
  return useMemo(() => {
    if (!accountsById) {
      return undefined
    }
    return Object.values(accountsById).filter(filter)
  }, [accountsById, filter])
}

export const useAccount = (
  accountId: number,
  options: { withReconciledBalance?: boolean },
  queryOptions?: WUseQueryOptions<Account, unknown, Account>
) => {
  return useQuery<Account, unknown, Account>({
    queryKey: ['accounts', accountId],
    queryFn: () => fetchAccount(accountId, options),
    ...queryOptions
  })
}

type AccountAmountsByMonthQueryKey = [
  string,
  number | undefined,
  string,
  string
]
export const useAccountAmountsByMonth = (
  {
    accountId,
    beginDate,
    endDate
  }: {
    accountId?: number
    beginDate: string
    endDate: string
  },
  options?: Omit<
    WUseQueryOptions<
      AccountMonthActualBudget[],
      unknown,
      AccountMonthActualBudget[],
      AccountAmountsByMonthQueryKey
    >,
    'queryFn' | 'queryKey'
  >
) => {
  return useQuery<
    AccountMonthActualBudget[],
    unknown,
    AccountMonthActualBudget[],
    AccountAmountsByMonthQueryKey
  >({
    queryKey: ['accounts-amount-by-month', accountId, beginDate, endDate],
    queryFn: fetchAccountAmountsByMonth,
    ...options
  })
}

interface CategoryAccount {
  type: 'Expense' | 'Income' | 'Orphan'
}

export const isCategoryAccount = (
  account: Account
): account is Account & CategoryAccount => {
  return (
    account.type === 'Expense' ||
    account.type === 'Income' ||
    account.type === 'Orphan'
  )
}

export const canBeCategoryOppositeAccount = (account: Account) => {
  return account.type !== 'Expense' && account.type !== 'Income'
}

export const canBeAccountOppositeAccount = (account: Account) => {
  return account.type !== 'Bank'
}

export const isAccount = (item: any): item is Account => {
  return typeof item.account_id !== 'undefined'
}

export const getAccountLevel = (account: Account) => {
  if (!account.path) {
    console.warn('Cannot get level of account without path, must be an error.')
    return 0
  }
  const level = account.path.split('> ').length
  return level
}

export const invalidateChartAccounts = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({
    predicate: (query: Query) => {
      if (!query.queryKey) {
        return false
      }
      return (
        query.queryKey[0] === '/charts' ||
        (typeof query.queryKey[0] === 'string' &&
          !!query.queryKey[0].match(/\/charts\/.*\/accounts/))
      )
    }
  })
}

export const categorizeAccountEntries = async ({
  accountId
}: {
  accountId: Account['account_id']
}) => {
  const res = await post<string>(`/accounts/${accountId}/categorize`)
  const [
    lastImportDate,
    newEntriesNb,
    doubleNb,
    dispatchedNb,
    unknownNb,
    entryIds
  ] = res.split(';', 6)
  return {
    lastImportDate,
    newEntriesNb,
    doubleNb,
    dispatchedNb,
    unknownNb,
    entryIds: entryIds
      .split(',')
      .map((n) => parseInt(n, 10))
      .filter((x) => !Number.isNaN(x)) as Entry['entry_id'][]
  }
}

export const invalidateAccount = (
  queryClient: QueryClient,
  accountId: Account['account_id']
) => {
  queryClient.invalidateQueries(['accounts', accountId])
}

export const invalidateAccountEntries = (
  queryClient: QueryClient,
  accountId: Account['account_id']
) => {
  queryClient.invalidateQueries({
    predicate: (query) => {
      const { queryKey } = query
      if (!Array.isArray(queryKey)) {
        return false
      }
      return (
        queryKey[0] === 'entries' &&
        queryKey[1] === 'account' &&
        queryKey[2] === accountId
      )
    }
  })
}

/**
 * The AccountsByIdProvider is a perf improvement over using useAccountsById in every children
 */
type AccountsByIdType = Record<Account['account_id'], Account> | undefined
export const AccountsByIdContext = React.createContext<AccountsByIdType>({})

export const useAccountsByIdContext = () => {
  return useContext(AccountsByIdContext)
}

export const AccountsByIdProvider = ({
  children,
  ...queryOptions
}: {
  children: React.ReactNode
} & UseQueryOptions<any>) => {
  const { data: accountsById } = useAccountsById(queryOptions)
  return (
    <AccountsByIdContext.Provider value={accountsById}>
      {children}
    </AccountsByIdContext.Provider>
  )
}

export const canImportTransactionsInAccount = (account: Account) =>
  account.type !== 'Orphan'
