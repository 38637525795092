import FlagStore, { FlagName, FlagValue } from './store'

const store = new FlagStore()

/**
 * Public API to use flags
 */
const flag = function flag(...args: [FlagName] | [FlagName, FlagValue]) {
  if (args.length === 1) {
    return store.get(args[0])
  } else {
    const [name, value] = args
    store.set(name, value)
    return value
  }
}

/** List all flags from the store */
export const listFlags = () => {
  return store.keys().sort()
}

/** Resets all the flags */
export const resetFlags = () => {
  listFlags().forEach((name) => store.remove(name))
}

/**
 * Enables several flags
 *
 * Supports passing either  object flagName -> flagValue
 *
 * @param {string[]|Object} flagsToEnable
 */
export const enable = (flagsToEnable: FlagName[]) => {
  let flagNameToValue
  if (Array.isArray(flagsToEnable)) {
    // eslint-disable-next-line no-console
    console.log(
      'flags.enable: Deprecation warning: prefer to use an object { flag1: true, flag2: true } instead of an array when using flags.enable'
    )
    flagNameToValue = flagsToEnable.map((flagName) => [flagName, true])
  } else if (typeof flagsToEnable === 'object') {
    flagNameToValue = Object.entries(flagsToEnable)
  }

  if (!flagNameToValue) {
    return
  }

  flagNameToValue.forEach(([flagName, flagValue]) => {
    flag(flagName as FlagName, flagValue)
  })
}
flag.store = store
flag.list = listFlags
flag.reset = resetFlags
flag.enable = enable

export const setFlagsForUser = (email: string) => {
  if (
    email === 'pt.browne@gmail.com' ||
    email === 'aurelien.chirouze@gmail.com' ||
    email === 'capaccess.chirouze@gmail.com' ||
    /pt.browne\+?([a-z0-9])*@gmail\.com/.exec(email)
  ) {
    flag('debug', true)
  }
}

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.flag = flag

  // @ts-ignore
  window.debug = (val = true) => flag('debug', val)
  flag('newBackendFlow', true)
  flag('welcomeTab', true)
  flag('bridge-backend', true)

  if (flag('allow-categorize-orphan') === undefined) {
    flag('allow-categorize-orphan', false)
  }
}
export default flag
