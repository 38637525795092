import { HomeWorkTwoTone } from '@mui/icons-material'
import { SxProps, Theme, Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import bankImageLarge from 'src/assets/bank_s.svg'
import checkingImageLarge from 'src/assets/checking_s.svg'
import expenseImageLarge from 'src/assets/expense_s.svg'
import folderImage from 'src/assets/folder.svg'
import incomeImageLarge from 'src/assets/income_s.svg'
import tagsImage from 'src/assets/tags_s.svg'
import { Tag, isRealEstate } from 'src/data/tags'
import { makeStyles } from 'src/utils/makeStyles'

const accountTypeImageByType = {
  Asset: folderImage,
  Income: incomeImageLarge,
  Expense: expenseImageLarge,
  Checking: checkingImageLarge,
  Bank: bankImageLarge,
  Tags: tagsImage
}

const useStyles = makeStyles()(() => ({
  root: {
    width: 20,
    height: 22
  },
  textIcon: {
    width: 16,
    height: 16,
    marginBottom: 0,
    marginRight: 4,
    fontSize: 16
  },
  small: {
    width: 10,
    height: 10,
    fontSize: 10,
    marginRight: 4
  },
  noMargin: {
    marginRight: 0
  },
  tagIcon: {
    fontSize: 16
  }
}))

export const TagIcon = ({
  className,
  size,
  sx,
  tag
}: {
  className?: string
  size?: 'small'
  sx?: SxProps<Theme>
  tag?: Tag
}) => {
  const { classes, cx } = useStyles()
  if (tag && isRealEstate(tag)) {
    return (
      <HomeWorkTwoTone
        htmlColor="#625cbb"
        className={cx(
          className,
          size === 'small' ? classes.small : classes.tagIcon
        )}
      />
    )
  }
  return (
    <Box
      component="img"
      sx={sx}
      alt=""
      src={tagsImage}
      className={cx(className, size === 'small' ? classes.small : undefined)}
    />
  )
}

const TypeIcon = ({
  type,
  className,
  textIcon,
  size,
  noMargin,
  ...rest
}: {
  type: string
  className?: string
  textIcon?: boolean
  size?: 'default' | 'small'
  noMargin?: Boolean
}) => {
  const image =
    accountTypeImageByType[type as keyof typeof accountTypeImageByType]
  const { classes, cx } = useStyles()
  return image ? (
    <img
      src={image}
      alt="Icône de type de compte"
      className={cx(
        classes.root,
        textIcon ? classes.textIcon : null,
        noMargin ? classes.noMargin : null,
        size === 'small' ? classes.small : null,
        className
      )}
      {...rest}
    />
  ) : null
}

TypeIcon.propTypes = {
  type: PropTypes.oneOf([
    'Asset',
    'Income',
    'Expense',
    'Checking',
    'Bank',
    'Tags'
  ]),
  className: PropTypes.string,
  textIcon: PropTypes.bool
}

export default TypeIcon
