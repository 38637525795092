/**
 * To be used in switch to check that every case has been handled
 * @see https://www.fullstory.com/blog/discriminated-unions-and-exhaustiveness-checking-in-typescript
 */
export function assertExhaustive(value: never, message?: string): never {
  if (typeof message === 'undefined') {
    message = `Reached unexpected case in exhaustive switch: ${value}`
  }
  throw new Error(message)
}

export default assertExhaustive
