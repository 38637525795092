function hex(buffer: ArrayBuffer): string {
  let digest = ''
  const view = new DataView(buffer)
  for (let i = 0; i < view.byteLength; i += 4) {
    // We use getUint32 to reduce the number of iterations (notice the `i += 4`)
    const value = view.getUint32(i)
    // toString(16) will transform the integer into the corresponding hex string
    // but will remove any initial "0"
    const stringValue = value.toString(16)
    // One Uint32 element is 4 bytes or 8 hex chars (it would also work with 4
    // chars for Uint16 and 2 chars for Uint8)
    const padding = '00000000'
    const paddedValue = (padding + stringValue).slice(-padding.length)
    digest += paddedValue
  }

  return digest
}

const SECRET = '^SxkUTnM7C$A@XXmUeVw'

/**
 * Used to generate a UUID from a value, that is stable across sessions.
 */
async function generateStableUUID(value: string) {
  const buffer = new TextEncoder().encode(`${value}:${SECRET}`)
  // Step 1: Create a hash of the email and secret using SHA-256
  const hash = await crypto.subtle.digest('SHA-256', buffer).then((x) => {
    return hex(x)
  })

  const uuid = `${hash.substring(0, 8)}-${hash.substring(
    8,
    4
  )}-${hash.substring(12, 4)}-${hash.substring(16, 4)}-${hash.substring(
    20,
    12
  )}`

  return uuid
}

export default generateStableUUID
