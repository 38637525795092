// import './wdyr'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ThemeProvider, Theme } from '@mui/material/styles'
import StylesProvider from '@mui/styles/StylesProvider'
import { QueryClientProvider } from '@tanstack/react-query'
import { fr } from 'date-fns/locale' // Import locale files for specific languages
import setDefaultOptions from 'date-fns/setDefaultOptions'
import React, { lazy } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Outlet } from 'react-router-dom'

import GlobalStyles from 'src/components/GlobalStyles'
import SnackbarProvider from 'src/components/SnackbarProvider'
import { ModalsProvider } from 'src/components/modal-controller'
import modals from 'src/modals'
import { darkTheme, lightTheme } from 'src/theme'
import usePageTracking from 'src/utils/usePageTracking'

import APIServerStatus from './APIServerStatus'
import DragLayer from './DragLayer'
import ErrorHandler from './ErrorHandler'
import NavigationHotkeys from './NavigationHotkeys'
import queryClient from './client'
import PWARefreshCheck from './components/PWARefreshCheck'
import { EventEmitterContext, globalEventEmitter } from './eventEmitter'
import { useFlag } from './flags'
import { IsMobileProvider } from './useIsMobile'
import UrlCallbackCheck from './utils/UrlCallbackCheck'

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools').then((m) => ({
    default: m.ReactQueryDevtools
  }))
)
const ZustandDevtool = lazy(() => import('src/components/ZustandDevtool'))

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true
})

setDefaultOptions({ locale: fr })

// Create a client
const App = () => {
  const darkMode = useFlag('dark')
  const isScreenshotting = useFlag('screenshots')
  const theme = darkMode ? darkTheme : lightTheme

  usePageTracking()

  return (
    <ErrorHandler>
      <EventEmitterContext.Provider value={globalEventEmitter}>
        {/* @ts-expect-error */}
        <DndProvider backend={HTML5Backend}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StylesProvider injectFirst>
              <SnackbarProvider>
                <QueryClientProvider client={queryClient}>
                  <ThemeProvider theme={theme}>
                    <IsMobileProvider>
                      <CacheProvider value={muiCache}>
                        <GlobalStyles />
                        <APIServerStatus />
                        <ModalsProvider initialModals={modals}>
                          <UrlCallbackCheck />
                          <PWARefreshCheck />
                          <Outlet />
                          <NavigationHotkeys />
                        </ModalsProvider>
                        {isScreenshotting ||
                        import.meta.env.MODE === 'production' ? null : (
                          <ZustandDevtool />
                        )}
                        {isScreenshotting ||
                        import.meta.env.MODE === 'production' ? null : (
                          <ReactQueryDevtools initialIsOpen={false} />
                        )}
                      </CacheProvider>
                    </IsMobileProvider>
                    <DragLayer />
                  </ThemeProvider>
                </QueryClientProvider>
              </SnackbarProvider>
            </StylesProvider>
          </LocalizationProvider>
        </DndProvider>
      </EventEmitterContext.Provider>
    </ErrorHandler>
  )
}

export default App
