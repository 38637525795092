import { useMemo } from 'react'

import { useAccounts } from 'src/data/accounts'
import { useTags } from 'src/data/tags'
import { useDashboardStore } from 'src/stores/dashboard'
import truthy from 'src/utils/truthy'

export const makeTreeStateKey = (name: string) => `treeState-${name}`

/**
 * Manages current tab and items displayed
 *
 * Used in both mobile and desktop trees
 */
const useAccountsTreeState = (showLiability?: boolean) => {
  const { tab, setTab } = useDashboardStore((x) => ({
    tab: x.accountsTreeTab,
    setTab: x.setAccountsTreeTab
  }))
  const {
    data: {
      assetRoot,
      incomeRoot,
      expenseRoot,
      incomeAccounts,
      expenseAccounts,
      liabilityRoot
    }
  } = useAccounts()
  const { data: tags } = useTags()

  const showAssets = tab === 'Bank'

  const bankItems = useMemo(
    () =>
      (showLiability &&
      liabilityRoot &&
      liabilityRoot.children &&
      liabilityRoot.children.length > 0
        ? [assetRoot, liabilityRoot]
        : [assetRoot]
      ).filter(truthy),
    [showLiability, liabilityRoot, assetRoot]
  )
  const items = useMemo(() => {
    if (showAssets && assetRoot) {
      return bankItems
    } else if (tab === 'Income' && incomeAccounts) {
      return incomeAccounts
    } else if (tab === 'Expense' && expenseAccounts) {
      return expenseAccounts
    } else if (tab === 'Tags' && tags && tags[0].children) {
      return tags[0].children
    }
    return []
  }, [incomeAccounts, expenseAccounts, tags, showAssets, assetRoot, tab])
  return {
    tags,
    assetRoot,
    incomeRoot,
    expenseRoot,
    items,
    tab,
    setTab
  }
}

export default useAccountsTreeState
