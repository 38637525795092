import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import * as Sentry from '@sentry/react'
import React from 'react'

import Page from 'src/components/Page'

import useEvent from './utils/useEvent'

const reloadPageWithoutFragment = () => {
  // Reloads the page without the fragment
  window.location.replace(window.location.toString().replace(/#.*/, ''))
}

interface FallbackRenderArg {
  error: Error
  componentStack: string | null
}

const ErrorFallback = ({
  error,
  componentStack
}: FallbackRenderArg): React.ReactElement => {
  const handleClick = useEvent(() => {
    localStorage.clear()
    reloadPageWithoutFragment()
  })
  return (
    <Page title="Winancial - Oups">
      <Container maxWidth="sm">
        <Paper sx={{ p: 2, mt: 3 }}>
          <Box mb={2}>
            <Typography gutterBottom variant="body1">
              Mince... quelque chose s&apos;est mal passé. Notre équipe a été
              avertie.
            </Typography>
            <Typography align="center">
              <Button onClick={handleClick} color="primary" variant="contained">
                Recharger l&apos;application
              </Button>
            </Typography>
          </Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">
                Plus d&apos;informations sur l&apos;erreur
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%" style={{ overflowX: 'auto' }}>
                <div>{error.toString()}</div>
                <pre>{componentStack}</pre>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Container>
    </Page>
  )
}

const ErrorHandler = ({ children }: { children: React.ReactNode }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => {
        return <ErrorFallback error={error} componentStack={componentStack} />
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorHandler
