import { format } from 'date-fns'
import pickBy from 'lodash/pickBy'

import { post } from 'src/client'
import { Account } from 'src/data/accounts'
import { Backend, fetchBackends } from 'src/data/backends'
import truthy from 'src/utils/truthy'

type ConnectUrlResp = {
  redirect_url: string
}

type EditUrlResp = {
  redirect_url: string
}

type BridgeUserUUID = string

const getRedirectURL = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3000'
  }
  return undefined
}

// eslint-disable-next-line import/prefer-default-export
export const redirectToWebview = async ({
  context = ''
}: {
  context?: string
}) => {
  const urlResp = await post<ConnectUrlResp | null>(
    '/users/bridge/connectUrl',
    pickBy(
      {
        redirect_url: getRedirectURL(),
        context
      },
      truthy
    )
  )
  if (urlResp) {
    window.location.assign(urlResp.redirect_url)
  }
}

/**
 * Redirects to a Bridge
 */
export const redirectToManageWebview = async ({
  connectionId,
  backendId,
  reconnect
}: {
  connectionId?: string
  backendId: Backend['backend_id']
  reconnect?: boolean
  callbackState?: string
}) => {
  const editUrlResp = await post<EditUrlResp>(
    reconnect ? '/users/bridge/reconnectUrl' : '/users/bridge/editUrl',
    pickBy(
      {
        item_id: connectionId,
        context: backendId,
        redirect_url: getRedirectURL()
      },
      truthy
    )
  )
  if (!editUrlResp) {
    return
  }
  window.location.assign(editUrlResp.redirect_url)
}

export const createBackend = async (
  chartId: Account['account_id'],
  itemId: string,
  bridgeUserUUID?: BridgeUserUUID
): Promise<Backend['backend_id']> => {
  const newBackendId = await post<string>(
    `/charts/${chartId}/backends`,
    pickBy(
      {
        bank_login: bridgeUserUUID,
        bank_login2: '',
        bank_password: itemId,
        bank_name: 'bridge',
        next_running_date: format(new Date(), 'yyyy-MM-dd HH:mm')
      },
      truthy
    )
  )
  return parseInt(newBackendId, 10)
}

export const checkURLToSaveBackend = async (
  chartId: Account['account_id'],
  url: string
): Promise<
  | {
      status: 'created' | 'existing'
      backendId: number
    }
  | undefined
> => {
  const params = new URL(url).searchParams
  const userUUID = params.get('user_uuid')
  const itemId = params.get('item_id')
  const backendId = params.get('backend_id')
  const success = params.get('success')
  const context = params.get('context')
  if (userUUID && itemId) {
    const backends = await fetchBackends(chartId)
    const existingBackend = backends.find((b) => b.bank_password === itemId)
    if (existingBackend) {
      console.log('Already existing backend', existingBackend)
      return {
        status: 'existing',
        backendId: existingBackend.backend_id
      }
    } else if (success === 'true') {
      return {
        status: 'created',
        backendId: await createBackend(chartId, itemId, userUUID)
      }
    }
  }
  if (backendId) {
    return { status: 'existing', backendId: parseInt(backendId, 10) }
  }
  // User has interrupted flow
  if (context && userUUID && success === 'false') {
    return { status: 'existing', backendId: parseInt(context, 10) }
  }
  return undefined
}
