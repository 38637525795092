import { buttonClasses } from '@mui/material'
import { Theme, alpha, darken, lighten } from '@mui/material/styles'
import React, { forwardRef } from 'react'

import { withStyles } from 'src/utils/makeStyles'

import LoadingButton, { LoadingButtonProps } from './LoadingButton'

type Intent = 'error' | 'success' | 'info' | 'warning'
type IntentButtonProps = LoadingButtonProps & { intent: Intent }

const getColorFromProps = (
  theme: Theme,
  transformColor: (color: string, amount: number) => string,
  amount: number,
  intent: Intent
) => {
  if (!theme.palette[intent]) {
    return undefined
  }
  return transformColor(theme.palette[intent].main, amount)
}

export default withStyles(
  forwardRef<HTMLButtonElement, IntentButtonProps>(
    ({ intent, ...rest }: IntentButtonProps, ref) => {
      return <LoadingButton ref={ref} {...rest} />
    }
  ),
  (theme, props) => ({
    contained: {
      backgroundColor: getColorFromProps(
        theme,
        theme.palette.mode === 'dark' ? darken : lighten,
        0.8,
        props.intent
      ),
      transition: 'background-color 0.1s ease',
      color: alpha(
        theme.palette.text.primary,
        theme.palette.mode === 'dark' ? 1 : 0.95
      ),
      '&:hover': {
        backgroundColor: getColorFromProps(
          theme,
          theme.palette.mode === 'dark' ? darken : lighten,
          0.75,
          props.intent
        )
      }
    },
    outlined: {
      borderColor: getColorFromProps(
        theme,
        theme.palette.mode === 'dark' ? darken : lighten,
        0.65,
        props.intent
      ),
      color: getColorFromProps(theme, darken, 0.35, props.intent),
      '&:hover': {
        borderColor: getColorFromProps(
          theme,
          theme.palette.mode === 'dark' ? darken : lighten,
          0.45,
          props.intent
        ),
        backgroundColor: getColorFromProps(
          theme,
          theme.palette.mode === 'dark' ? darken : lighten,
          0.95,
          props.intent
        )
      }
    },
    disabled: {
      [`&.${buttonClasses.contained}`]: {
        backgroundColor: getColorFromProps(
          theme,
          theme.palette.mode === 'dark' ? darken : lighten,
          0.8,
          props.intent
        ),
        color: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
          backgroundColor: getColorFromProps(
            theme,
            theme.palette.mode === 'dark' ? darken : lighten,
            0.65,
            props.intent
          )
        }
      }
    }
  })
)
