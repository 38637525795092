// @ts-ignore We use StrictEventEmitter to type EventEmitter
import EventEmitter from 'microee'
import { useContext, useEffect, createContext } from 'react'
import StrictEventEmitter from 'strict-event-emitter-types'

interface Events {
  'cell-click-tags': void
  'cell-click-account_name': void
  'cell-click-opp_acc_name': void
  'cell-click-description': void
  'cell-click-date': void
  'cell-click-memo': void
  'next-transaction': void
  'previous-transaction': void
  'accounts-tree.expand-all': void
}

type GlobalEventEmitter = StrictEventEmitter<EventEmitter, Events>

// eslint-disable-next-line import/prefer-default-export
export const globalEventEmitter: GlobalEventEmitter = new EventEmitter()

export const EventEmitterContext =
  createContext<GlobalEventEmitter>(globalEventEmitter)

export const useEventEmitter = (
  event?: keyof Events,
  callback?: () => void
) => {
  const eventEmitterCtx = useContext(EventEmitterContext)
  const eventEmitter = eventEmitterCtx
  useEffect(() => {
    if (!eventEmitter || !event || !callback) {
      return
    }
    eventEmitter.on(event, callback)
    // eslint-disable-next-line consistent-return
    return () => {
      eventEmitter.removeListener(event, callback)
    }
  }, [eventEmitter, event, callback])

  return eventEmitter
}
