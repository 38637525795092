import createStoreHook from 'zustand'
import createStore from 'zustand/vanilla'

import { Entry } from 'src/data/entries'

export type Selection = {
  rowIds: NonNullable<Entry['entry_id']>[]
}

export type SelectionState = {
  selection: Selection
  setSelection: (newSel: Selection) => void
}

export const selectionSelector = (state: SelectionState) =>
  [state.selection, state.setSelection] as const

export const selectionStore = createStore<SelectionState>((set) => ({
  selection: {
    rowIds: []
  },
  setSelection: (newSel) => set({ selection: newSel })
}))
export const useSelectionStore = createStoreHook(selectionStore)
