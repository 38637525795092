/* eslint-disable import/order */
/* eslint-disable object-shorthand */

import americanexpress from '../assets/bank-logos/american-express@2x.webp'
import aviva from '../assets/bank-logos/aviva@2x.webp'
import banquepopulaire from '../assets/bank-logos/banque-populaire@2x.webp'
import bnpparibas from '../assets/bank-logos/bnp-paribas@2x.webp'
import boursedirect from '../assets/bank-logos/bourse-direct@2x.webp'
import boursorama from '../assets/bank-logos/boursorama@2x.webp'
import bred from '../assets/bank-logos/bred@2x.webp'
import caissedepargneparticuliers from '../assets/bank-logos/caisse-d-epargne-particuliers@2x.webp'
import cic from '../assets/bank-logos/cic@2x.webp'
import qonto from '../assets/bank-logos/qonto@2x.webp'
import caissedepargneprofessionnels from '../assets/bank-logos/caisse-d-epargne-professionnels@2x.webp'
import cbc from '../assets/bank-logos/cbc@2x.webp'
import creditagricole from '../assets/bank-logos/credit-agricole@2x.webp'
import creditmutuel from '../assets/bank-logos/credit-mutuel@2x.webp'
import fortuneo from '../assets/bank-logos/fortuneo@2x.webp'
import ingfrance from '../assets/bank-logos/ing-france@2x.webp'
import creditcooperatif from '../assets/bank-logos/credit-cooperatif@2x.webp'
import creditdunord from '../assets/bank-logos/credit-du-nord@2x.webp'
import labanquepostale from '../assets/bank-logos/la-banque-postale@2x.webp'
import lcl from '../assets/bank-logos/lcl@2x.webp'
import palatine from '../assets/bank-logos/palatine@2x.webp'
import paypalrestapi from '../assets/bank-logos/paypal-rest-api@2x.webp'
import revolut from '../assets/bank-logos/revolut@2x.webp'
import societegenerale from '../assets/bank-logos/societe-generale@2x.webp'
import unionfinancieredefrance from '../assets/bank-logos/union-financiere-de-france@2x.webp'
import yomoni from '../assets/bank-logos/yomoni@2x.webp'
import { Backend } from 'src/data/backends'

const bankIcons = {
  'caisse-d-epargne-particuliers': caissedepargneparticuliers,
  'caisse-d-epargne': caissedepargneparticuliers,
  'credit-agricole': creditagricole,
  'bnp-paribas': bnpparibas,
  boursorama: boursorama,
  'societe-generale': societegenerale,
  'la-banque-postale': labanquepostale,
  'banque-populaire': banquepopulaire,
  'caisse-d-epargne-professionnels': caissedepargneprofessionnels,
  'american-express': americanexpress,
  'credit-mutuel': creditmutuel,
  'credit-du-nord': creditdunord,
  fortuneo: fortuneo,
  'ing-france': ingfrance,
  'credit-cooperatif': creditcooperatif,
  'bourse-direct': boursedirect,
  palatine: palatine,
  'paypal-rest-api': paypalrestapi,
  bred: bred,
  revolut: revolut,
  aviva: aviva,
  'union-financiere-de-france': unionfinancieredefrance,
  yomoni: yomoni,

  // Put short banks at the end since they could be included in other names
  // and the current algorithm iterates to find the first match
  lcl: lcl,
  cic: cic,
  qonto: qonto,
  cbc: cbc
} as Record<string, string>

const removeDiacritics = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const normalizeBankName = (userBankName: string | null) => {
  if (!userBankName) {
    return userBankName
  }
  const normalized = removeDiacritics(userBankName)
    .replace(/\s+\(.*\)/g, '')
    .replace(/^\d+\./g, '')
    .trim()
    .toLowerCase()
    .replace(/-.*/g, '')
    .replace(/\s+|'/g, '-')
    .replace(/-$/g, '')
  return normalized
}

const bankKeys = Object.keys(bankIcons)

export const getBankIcon = (bankName: Backend['backend_name'] | null) => {
  const normalizedBankName = normalizeBankName(bankName)
  for (let i = 0; i < bankKeys.length; i++) {
    const k = bankKeys[i]
    if (normalizedBankName && normalizedBankName.includes(k)) {
      return bankIcons[k]
    }
  }
  return null
}
