import { uniqBy } from 'lodash'

const collect = <T extends unknown>(
  start: T,
  getNext: (item: T) => T | undefined
) => {
  let cur: T | undefined = start
  const res = []
  while (cur) {
    res.push(cur)
    cur = getNext(cur)
  }
  return res
}

export interface Flat<T> {
  value: T
  parents: T[]
}

interface Parent {
  children: any[]
}

/**
 * Flatten a tree into a list
 */
export const flattenTree = <T extends Parent>(
  children: T[],
  makeId: (item: T) => number | string
): Flat<T>[] => {
  const res = []
  const seen: Record<string, boolean> = {}
  type QueueItem = { value: T; parent?: T }
  const q: QueueItem[] = children
    .slice()
    .map((x) => ({ value: x, parent: undefined }))
  while (q.length) {
    const element = q.pop() as QueueItem
    if (element?.value?.children) {
      element.value.children.forEach((child) => {
        const id = makeId(child)
        if (seen[id]) {
          return
        }
        seen[id] = true
        q.push({ value: child, parent: element as unknown as T })
      })
    }

    if (element.value) {
      res.push({ value: element.value, parent: element.parent })
    }
  }
  return uniqBy(
    res.map(
      (element) =>
        ({
          value: element.value,
          // @ts-ignore
          parents: collect(element.parent, (x) => x.parent).reverse()
        } as Flat<T>)
    ),
    (x) => makeId(x.value)
  )
}

export const keyTreeBy = <T extends { children: any[] | undefined }>(
  trees: T[],
  key: (t: T) => string,
  res: Record<string, T>
) => {
  res = res || {}
  if (!trees) {
    return res
  }
  trees.forEach((tree) => {
    res[key(tree)] = tree
    if (tree.children) {
      keyTreeBy(tree.children, key, res)
    }
  })
  return res
}

interface Node<T extends Node<T>> {
  children: T[]
}

export const dfs = <T extends Node<T>>(start: T[]) => {
  const res: T[] = []
  const q = [...start]
  while (q.length > 0) {
    const item = q.pop()!
    res.push(item)
    if (item.children) {
      q.push(...(item.children as T[]))
    }
  }
  return res
}
