import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import * as yup from 'yup'

import { fetch, put } from 'src/client'
import { useCurrentUser } from 'src/data/users'
import useEvent from 'src/utils/useEvent'

export type CategoriesChartMode = 'pie' | 'bar'

const settingsSchema = yup
  .object({
    'accountView.grid.pageSize': yup.number().optional().default(25),
    'accountView.grid.fetchLimit': yup.number().optional().default(200),
    'crisp.enabled': yup.boolean().optional().default(true),
    'crisp.sharedEmail': yup.boolean().optional().default(false),
    'accounts.archived': yup.array(yup.number()).optional(),
    'synthesisView.categories.chartMode': yup
      .mixed<CategoriesChartMode>()
      .oneOf(['pie', 'bar'])
      .default('pie')
  })
  .transform((value, _, schema) => {
    if (!value) {
      return {}
    }
    const inputFields = Object.keys(value)
    const knownFields = new Set(Object.keys(schema.fields))
    const unknownFields = inputFields.filter((x) => !knownFields.has(x))
    unknownFields.forEach((unknownField) => {
      value[unknownField] = null
    })
    return value
  })

type SettingsType = yup.InferType<typeof settingsSchema>

export const useUserSettings = () => {
  const { data: user } = useCurrentUser()
  const resp = useQuery<unknown, unknown, SettingsType>({
    queryKey: [`settings`],
    queryFn: async () => {
      const data = await fetch(`/users/${user?.user_id}/settings`)
      return settingsSchema.cast(data) as SettingsType
    },
    enabled: !!user,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })
  return resp
}

type SettingsInput = Record<string, any>
export const useUpdateUserSettings = () => {
  const { data: user } = useCurrentUser()
  const client = useQueryClient()
  const mutation = useMutation<unknown, unknown, SettingsInput>(
    async (data) => {
      if (!user?.user_id) {
        return
      }
      // @ts-ignore
      client.setQueriesData(['settings'] as QueryKey, (oldData) => ({
        ...(typeof oldData === 'object' ? oldData : {}),
        ...data
      }))
      return put(`/users/${user.user_id}/settings`, { settings: data })
    },
    {
      onSuccess: () => {
        client.invalidateQueries([`settings`])
      }
    }
  )
  return mutation
}

type UserSettingHookResult<K extends keyof SettingsType> = {
  data: SettingsType[K]
  update: (value: SettingsType[K]) => void
  isLoading: boolean
}
export const useUserSetting = <K extends keyof SettingsType>(
  key: K
): UserSettingHookResult<K> => {
  const { data: settings, isLoading } = useUserSettings()
  const updateMutation = useUpdateUserSettings()
  const update = useEvent((value: unknown) => {
    return updateMutation.mutate({
      [key]: value
    })
  })
  return {
    data: settings?.[key] ?? settingsSchema.fields[key].spec.default,
    update,
    isLoading
  }
}
