import React from 'react'
import { Helmet } from 'react-helmet'

const Page = ({
  children,
  title,
  ...rest
}: {
  children: React.ReactNode
  title: string
} & React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </div>
)

export default Page
