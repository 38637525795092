import { MoreVert } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import PublishIcon from '@mui/icons-material/Publish'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled, Theme } from '@mui/material/styles'
import React, { MouseEvent, useCallback, useState } from 'react'

import { CreateAccountOrTagDialogProps } from 'src/components/CreateAccountOrTagDialog'
import { UpdateAccountOrTagDialogProps } from 'src/components/UpdateAccountOrTagDialog'
import {
  Account,
  canImportTransactionsInAccount,
  isAccount,
  isTransferAccount
} from 'src/data/accounts'
import { useMainChart } from 'src/data/charts'
import { isTag, Tag } from 'src/data/tags'
import flag from 'src/flags'
import { useModals } from 'src/modals'
import { makeStyles } from 'src/utils/makeStyles'

import { RemoveAccountOrTagDialogProps } from './RemoveAccountOrTagDialog'
import TypeIcon, { TagIcon } from './TypeIcon'

const canCreate = (account: Account | Tag) => {
  if (isTag(account)) {
    return true
  }
  const { type } = account
  return type !== 'Checking' && type !== 'Liability'
}

const MenuIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  marginRight: theme.spacing(1)
}))

const getAccountCreateText = (account: Account) => {
  const { type } = account
  if (type === 'Expense' || type === 'Income') {
    return 'Créer une catégorie'
  } else if (type === 'Bank') {
    return flag('newBackendFlow')
      ? 'Créer un compte fictif'
      : 'Créer un compte bancaire'
  } else if (type === 'Asset') {
    return 'Créer une banque'
  } else if (type === 'Cash') {
    return 'Créer un porte-monnaie'
  }
  return '...'
}
const getAccountDeleteText = (account: Account) => {
  const { type } = account
  if (type === 'Expense' || type === 'Income') {
    return 'Supprimer la catégorie'
  } else if (type === 'Bank' || type === 'Liability') {
    return 'Supprimer la banque'
  } else if (type === 'Cash') {
    return 'Supprimer le porte monnaie'
  }
  return 'Supprimer le compte bancaire'
}
const getAccountUpdateText = (account: Account) => {
  const { type } = account
  if (type === 'Expense' || type === 'Income') {
    return 'Modifier la catégorie'
  } else if (type === 'Bank') {
    return 'Modifier la banque'
  } else if (type === 'Cash') {
    return 'Modifier le porte monnaie'
  }
  return 'Modifier le compte bancaire'
}

export const shouldShowToolbar = (item: Account | Tag) => {
  return (
    (isAccount(item) &&
      !isTransferAccount(item) &&
      (item.type === 'Expense' ||
        item.type === 'Income' ||
        item.type === 'Bank' ||
        item.type === 'Liability' ||
        item.type === 'Cash' ||
        item.type === 'Asset' ||
        item.type === 'Checking')) ||
    isTag(item)
  )
}

type AccountOrTagToolbarProps = { item: Account | Tag }

const AccountOrTagToolbarMenu = ({
  item,
  anchorEl,
  onClose
}: AccountOrTagToolbarProps & {
  anchorEl: HTMLElement | null
  onClose: React.MouseEventHandler<HTMLElement>
}) => {
  const { openModal, closeModal } = useModals()
  const open = Boolean(anchorEl)

  const handleAddAsset = () => {
    openModal('CreateAccountOrTag', {
      addItemOptions: { type: 'Asset' },
      onSuccess: () => closeModal('CreateAccountOrTag'),
      onCancel: () => closeModal('CreateAccountOrTag'),
      parent: item
    })
  }

  const handleImportTransactions = () => {
    if (!isAccount(item)) {
      return
    }
    openModal('ImportTransactions', {
      accountId: item.account_id,
      onClose: () => closeModal('ImportTransactions')
    })
  }

  const handleAddItem = () => {
    openModal('CreateAccountOrTag', {
      addItemOptions: true,
      onSuccess: () => closeModal('CreateAccountOrTag'),
      onCancel: () => closeModal('CreateAccountOrTag'),
      parent: item
    } as CreateAccountOrTagDialogProps)
  }

  const handleUpdateItem = () => {
    openModal('UpdateAccountOrTag', {
      onSuccess: () => closeModal('UpdateAccountOrTag'),
      onCancel: () => closeModal('UpdateAccountOrTag'),
      item
    } as UpdateAccountOrTagDialogProps)
  }

  const handleRemoveItem = () => {
    openModal('RemoveAccountOrTag', {
      item,
      onClose: (ev: React.MouseEvent<HTMLButtonElement> | {}) => {
        // @ts-ignore
        ev?.preventDefault()
        closeModal('RemoveAccountOrTag')
      }
    } as RemoveAccountOrTagDialogProps)
  }

  const { data: mainChart } = useMainChart({ enabled: false })
  const chartId = mainChart?.account_id

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
      {canCreate(item) ? (
        <MenuItem onClick={handleAddItem}>
          <MenuIcon>
            {isAccount(item) ? (
              <>
                {item.type === 'Asset' ? (
                  <TypeIcon type="Bank" textIcon noMargin />
                ) : null}
                {item.type === 'Bank' ? (
                  <TypeIcon type="Checking" textIcon noMargin />
                ) : null}
                {item.type === 'Expense' || item.type === 'Income' ? (
                  <TypeIcon type={item.type} textIcon noMargin />
                ) : null}
              </>
            ) : (
              <TagIcon tag={item} />
            )}
          </MenuIcon>
          {isAccount(item) ? getAccountCreateText(item) : 'Créer un projet'}
        </MenuItem>
      ) : null}
      {isAccount(item) && item.type === 'Asset' ? (
        <MenuItem onClick={handleAddAsset}>
          <MenuIcon>
            <TypeIcon type="Asset" textIcon noMargin />
          </MenuIcon>
          Créer un actif
        </MenuItem>
      ) : null}
      {isAccount(item) && canImportTransactionsInAccount(item) ? (
        <MenuItem onClick={handleImportTransactions}>
          <MenuIcon>
            <PublishIcon />
          </MenuIcon>
          Importer des opérations
        </MenuItem>
      ) : null}
      {isAccount(item) &&
      item.type === 'Asset' &&
      item.parent_id === chartId ? null : (
        <MenuItem onClick={handleRemoveItem}>
          <MenuIcon>
            <RemoveCircle fontSize="small" />
          </MenuIcon>
          {isAccount(item) ? getAccountDeleteText(item) : 'Supprimer le projet'}
        </MenuItem>
      )}
      <MenuItem onClick={handleUpdateItem}>
        <MenuIcon>
          <EditIcon fontSize="small" />
        </MenuIcon>
        {isAccount(item) ? getAccountUpdateText(item) : 'Modifier le projet'}
      </MenuItem>
    </Menu>
  )
}

const useToolbarStyles = makeStyles()({
  icon: {
    opacity: 0.5,
    '.MuiTreeItem-label:hover &': {
      opacity: 1
    }
  }
})

const ConditionalAccountOrTagToolbar = (props: AccountOrTagToolbarProps) => {
  const { item } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { classes } = useToolbarStyles()
  const handleClick = useCallback((ev: MouseEvent<HTMLElement>) => {
    ev.preventDefault()
    setAnchorEl(ev.currentTarget)
  }, [])

  const handleClose: React.MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    setAnchorEl(null)
  }

  return shouldShowToolbar(item) ? (
    <>
      <IconButton className={classes.icon} onClick={handleClick} size="small">
        <MoreVert fontSize="small" />
      </IconButton>
      {anchorEl ? (
        <AccountOrTagToolbarMenu
          item={item}
          anchorEl={anchorEl}
          onClose={handleClose}
        />
      ) : null}
    </>
  ) : null
}

export default ConditionalAccountOrTagToolbar
