import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { fetch } from 'src/client'

/**
 * str2ab and importRsaKey are from
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#examples
 *
 * Adapted to use Typescript
 */

export type PEMString = string

/*
Convert a string into an ArrayBuffer
from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
*/
export function str2ab(str: string): ArrayBuffer {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

function importRsaKey(pem: PEMString) {
  // fetch the part of the PEM string between header and footer
  const pemHeader = '-----BEGIN PUBLIC KEY-----\n'
  const pemFooter = '\n-----END PUBLIC KEY-----'

  const pemContents = pem.substring(
    pemHeader.length,
    pem.length - pemFooter.length
  )
  // base64 decode the string to get the binary data
  const binaryDerString = window.atob(pemContents)
  // convert from a binary string to an ArrayBuffer
  const binaryDer = str2ab(binaryDerString)

  return window.crypto.subtle.importKey(
    'spki',
    binaryDer,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-1'
    },
    true,
    ['encrypt']
  )
}

export const fetchAutofeedPublicKey = async (): Promise<CryptoKey> => {
  const publicKeyPem = await fetch<string>('/static/autofeed_public_key.asc')
  const key = await importRsaKey(publicKeyPem)
  return key
}

export const useEncrypt = () => {
  const { data: publicKey, isLoading } = useQuery({
    queryKey: ['autofeedPublicKey'],
    queryFn: () => fetchAutofeedPublicKey()
  })
  const encryptWithPublicKey = useCallback(
    (text: string) => {
      if (!publicKey) {
        throw new Error(
          'Public key us not not ready, use isLoading to know if it has been loaded.'
        )
      }
      return encrypt(publicKey, text)
    },
    [publicKey]
  )

  return {
    isLoading,
    encrypt: encryptWithPublicKey
  }
}

export const encrypt = async (publicKey: CryptoKey, text: string) => {
  const enc = new TextEncoder()
  const encoded = enc.encode(text)
  const encrypted = await window.crypto.subtle.encrypt(
    'RSA-OAEP',
    publicKey,
    encoded
  )
  const b64str = arrayBufferToBase64(encrypted)
  return b64str
}
