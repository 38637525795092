import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { differenceInCalendarDays, differenceInDays, isBefore } from 'date-fns'
import parse from 'date-fns/parse'

import { fetch, post } from 'src/client'

type RawPlan = {
  email: string
  plan_is_active: boolean
  plan_creation_date: string
  plan_end_date: string
  plan: string
  trial_plan_possible: boolean
  amount: number
}

const PLAN_PREFIXES = ['TRIAL', 'FREE', 'BASE', 'PREM', 'MAX', 'PRO']

const INITIAL_TRIAL_PLAN_DURATION = 3

export const isPlanInTrialPeriod = (plan: Plan) => {
  return plan.amount === 0
}

export const hasPlanBeenExtended = (plan: Plan) => {
  const planDuration = getPlanDuration(plan)
  return planDuration > INITIAL_TRIAL_PLAN_DURATION + 1
}

export type Plan = Omit<RawPlan, 'plan_creation_date' | 'plan_end_date'> & {
  plan_end_date: Date
  plan_creation_date: Date
  plan_recurrence: '1M' | '2M' | '3M' | '1Y'

  // Should be kept in sync with PLAN_PREFIX above
  plan_name: 'TRIAL' | 'FREE' | 'BASE' | 'PREM' | 'MAX' | 'PRO'
}

export const getPlanDuration = (plan: Plan) => {
  return differenceInDays(plan.plan_end_date, plan.plan_creation_date)
}

const PLAN_DATE_FORMAT = 'yyyy-MM-dd'

export const parsePlan = (plan: RawPlan): Plan => {
  const planName = PLAN_PREFIXES.find((prefix) =>
    plan.plan.startsWith(prefix)
  ) as Plan['plan_name']
  return {
    ...plan,
    plan_name: planName,
    plan_recurrence: plan.plan
      .replace(new RegExp(`^${planName}`), '')
      .slice(0, 2) as Plan['plan_recurrence'],
    plan_end_date: parse(plan.plan_end_date, PLAN_DATE_FORMAT, new Date()),
    plan_creation_date: parse(
      plan.plan_creation_date,
      PLAN_DATE_FORMAT,
      new Date()
    )
  }
}

export const shouldShowWarningForDaysLeft = (daysLeft: number) => {
  return daysLeft < 2
}

export const getDaysLeft = (plan?: Plan) => {
  if (!plan?.plan_end_date) {
    return 0
  }
  return differenceInCalendarDays(plan?.plan_end_date, new Date())
}

export const isTrialRecurrence = (recurrence?: string) => {
  return recurrence === '2M'
}

export const isTrialPlan = (plan?: Plan) => {
  return isTrialRecurrence(plan?.plan_recurrence)
}

export const isFreePlan = (plan: Plan) => {
  return plan.plan === 'FREE'
}

export const isPlanExpired = (plan: Plan) => {
  const { plan_end_date } = plan
  // The backend automatically returns a free plan where all other plans
  // have expired
  if (isFreePlan(plan)) {
    return true
  }

  return isBefore(plan_end_date, new Date())
}

export const isTrialPlanPossible = (plan: Plan) => {
  return !!plan.trial_plan_possible
}

type FetchPlanOptions = {
  includeExpired?: boolean
}

export const extendTrialPlan = () => {
  return post('/plan/trial/extend')
}

export const fetchPlan = (
  { includeExpired }: FetchPlanOptions = {
    includeExpired: false
  }
) =>
  fetch<RawPlan>(
    `/plan?include_expired=${includeExpired ? 'true' : 'false'}`
  ).then(parsePlan)

type PlanQueryOptions = UseQueryOptions<RawPlan, Error, Plan> & FetchPlanOptions

export const planQuery = ({
  includeExpired = false,
  ...queryOptions
}: PlanQueryOptions): UseQueryOptions<RawPlan, Error, Plan> => {
  return {
    queryKey: [`/plan?include_expired=${includeExpired ? 'true' : 'false'}`],
    ...queryOptions,
    select: parsePlan
  }
}

export const usePlan = (options: PlanQueryOptions = {}) => {
  return useQuery<RawPlan, Error, Plan>(planQuery(options))
}

export const useSubscribeToTrial = () => {
  const mutation = useMutation(async () => {
    await post(`/plan/trial`)
  })
  return mutation
}
