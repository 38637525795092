import createStoreHook from 'zustand'
import { persist } from 'zustand/middleware'
import createStore from 'zustand/vanilla'

export type SettingsState = {
  entriesFetchLimit: number
  setEntriesFetchLimit: (n: number) => void
  showArchivedAccounts: boolean
  setShowArchivedAccounts: (s: boolean) => void
}

export const settingsStore = createStore<SettingsState>(
  persist(
    (set) => ({
      showArchivedAccounts: false as boolean,
      setShowArchivedAccounts: (showArchivedAccounts: boolean) =>
        set({ showArchivedAccounts }),
      // Controls the number of entries that are fetched in accounts view
      entriesFetchLimit: 200,
      setEntriesFetchLimit: (fetchLimit: number) =>
        set({ entriesFetchLimit: fetchLimit })
    }),
    {
      name: 'settings',
      getStorage: () => localStorage
    }
  )
)

export const useSettingsStore = createStoreHook(settingsStore)
