import { SnackbarOrigin, Theme } from '@mui/material'
import Slide from '@mui/material/Slide'
import { SnackbarProvider } from 'notistack'
import React from 'react'

import { makeStyles } from 'src/utils/makeStyles'

const useSnackbarStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: 600
  },
  variantError: {
    '& button': {
      color: theme.palette.error.contrastText
    }
  },
  variantSuccess: {
    '& button': {
      color: theme.palette.error.contrastText
    }
  },
  variantInfo: {
    '& button': {
      color: theme.palette.error.contrastText
    }
  }
}))

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
}

const WinancialSnackbarProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { classes: snackbarClasses } = useSnackbarStyles()

  return (
    <SnackbarProvider
      anchorOrigin={snackbarAnchorOrigin}
      classes={snackbarClasses}
      /* @ts-ignore */
      TransitionComponent={Slide}
    >
      {children}
    </SnackbarProvider>
  )
}

export default WinancialSnackbarProvider
