import {
  accordionSummaryClasses,
  colors,
  createTheme,
  inputClasses,
  PaletteOptions,
  Theme
} from '@mui/material'
import { alpha, darken, lighten } from '@mui/material/styles'

import type {} from '@mui/x-data-grid/themeAugmentation'

import shadows from './shadows'
import typography from './typography'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true
  }
}

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }

  interface Palette {
    default: Color
  }

  interface TypeBackground {
    dark: string
    page: string
  }
}

const lightPalette = {
  mode: 'light',
  background: {
    page: 'none',
    dark: '#F4F6F8',
    default: colors.common.white,
    paper: colors.common.white
  },
  info: {
    main: 'rgb(33, 150, 243)',
    dark: '#648dae',
    light: '#6ec6ff'
  },
  success: {
    main: '#3FA40F',
    light: '#3FA40F'
  },
  primary: {
    main: '#021B2C',
    light: '#A2D3F5'
  },
  secondary: {
    main: '#026CA3',
    light: '#EBF8FF',
    dark: '#002C42'
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600]
  },
  default: {
    main: '#e7f3fd',
    dark: darken('#e7f3fd', 0.035)
  }
} as PaletteOptions

const darkPalette = {
  mode: 'dark',
  background: {
    page: '#1C1D21',
    dark: '#F4F6F8',
    default: colors.common.white,
    paper: '#1C1D21'
  },
  primary: {
    main: '#021B2C',
    light: '#A2D3F5'
  },
  secondary: {
    main: '#6B7188',
    light: lighten('#6B7188', 0.925)
  },
  text: {
    primary: colors.blueGrey[100],
    secondary: colors.blueGrey[100]
  },
  default: {
    main: darken('#A2D3F5', 0.875),
    dark: darken('#A2D3F5', 0.825)
  }
} as PaletteOptions

const makeOverrides = <T extends Theme>(theme: T): T['components'] => {
  const isDark = theme.palette.mode === 'dark'

  const res: T['components'] = {
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:nth-of-type(2)': {
            paddingTop: 0
          }
        }
      }
    },

    MuiSwitch: {
      defaultProps: {
        color: 'secondary'
      }
    },

    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      }
    },

    MuiChip: {
      styleOverrides: {
        textTransform: 'none',

        outlinedSecondary: {
          color: theme.palette.text.primary,
          borderColor:
            theme.palette.mode === 'dark'
              ? darken(theme.palette.secondary.main, 0.35)
              : 'rgb(189, 203, 210)',

          '& $deleteIcon': {
            borderColor:
              theme.palette.mode === 'dark'
                ? darken(theme.palette.secondary.main, 0.35)
                : 'rgb(189, 203, 210)'
          }
        }
      }
    },

    MuiRadio: {
      defaultProps: {
        color: 'secondary'
      }
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          userSelect: 'none',
          '& .MuiDataGrid-cell': {
            fontSize: '12px',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '500',
            fontSize: '12px'
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            paddingLeft: '5px',
            paddingRight: '5px'
          },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
            outline: 0
          }
        },
        footerContainer: {
          minHeight: 32
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            backdropFilter: 'blur(5px)'
          }
        }
      }
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: 0.15,
          lineHeight: 1.25,
          minHeight: 32,
          transition: 'transform 0.125s ease',
          '&:active': {
            transform: 'scale(0.975)'
          }
        },
        startIcon: {
          marginRight: 4
        },
        iconSizeSmall: {
          '& > :first-of-type': {
            fontSize: '1em !important'
          }
        },
        contained: {
          color: 'black',
          transition: '0.125s background-color ease',
          borderRadius: theme.shape.borderRadius,
          '&:active, &:hover': {
            boxShadow: 'none'
          },
          '&.Mui-disabled': {
            backgroundColor: isDark
              ? darken(theme.palette.secondary.main, 0.925)
              : lighten(theme.palette.secondary.main, 0.9)
          }
        },
        containedDefault: {
          boxShadow: 'none',
          '&:active, &:hover': {
            boxShadow: 'none'
          },
          color: theme.palette.getContrastText(theme.palette.default.main)
        },
        textDefault: {
          color: isDark ? 'white' : lighten(theme.palette.primary.main, 0.25)
        },
        containedPrimary: {
          color: theme.palette.getContrastText(
            isDark ? theme.palette.primary.dark : theme.palette.primary.main
          ),
          '&.Mui-disabled': {
            color: 'white',
            backgroundColor: isDark
              ? theme.palette.primary.dark
              : theme.palette.primary.main
          }
        },
        containedSizeSmall: {
          fontSize: 13
        },
        containedSizeLarge: {
          fontSize: 13,
          minHeight: '2.5rem'
        }
      },
      defaultProps: {
        color: 'default'
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          backgroundColor: isDark
            ? darken(theme.palette.secondary.main, 0.95)
            : lighten(theme.palette.secondary.main, 0.925),
          '&:hover': {
            backgroundColor: isDark
              ? darken(theme.palette.secondary.main, 0.95)
              : lighten(theme.palette.secondary.main, 0.9)
          },
          '&.Mui-focused': {
            backgroundColor: isDark
              ? darken(theme.palette.secondary.main, 0.9)
              : lighten(theme.palette.secondary.main, 0.9)
          },
          '&.Mui-disabled': {
            backgroundColor: isDark
              ? darken(theme.palette.secondary.main, 0.95)
              : lighten(theme.palette.secondary.main, 0.9)
          }
        }
      },
      defaultProps: {
        disableUnderline: true
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40
        },
        flexContainer: {
          height: '100%'
        },
        indicator: {
          background: isDark ? darken('#026DA2', 0.25) : '#026DA2'
        }
      }
    },
    // @ts-ignore
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            display: 'none'
          }
        }
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          height: '100%',
          fontWeight: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          fontSize: '0.75rem',
          minHeight: 40,
          padding: '0px 0.75rem',
          [theme.breakpoints.up('sm')]: {
            minWidth: 'auto'
          },
          color: alpha(theme.palette.text.primary, 0.8),
          '&.Mui-selected': {
            fontWeight: 'normal',
            color: theme.palette.text.primary
          }
        },
        textColorInherit: {
          opacity: 0.8
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          [theme.breakpoints.up('sm')]: {
            minHeight: 32
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: 14,
          paddingLeft: 10
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
            padding: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          [`&.${accordionSummaryClasses.expanded}`]: {
            minHeight: 48
          }
        },
        content: {
          marginTop: 0,
          marginBottom: 0,
          [`&.${accordionSummaryClasses.expanded}`]: {
            marginTop: 0,
            marginBottom: 0
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          flexGrow: 1
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: typography.body2.fontSize
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBottom: '1rem'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: '#ebf8ff'
        },
        standardWarning: {
          backgroundColor: '#FFF9E6'
        },
        standardSuccess: {
          backgroundColor: '#EEFFD7'
        },
        message: {
          [theme.breakpoints.down('sm')]: {
            fontSize: 14
          }
        }
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: lighten(theme.palette.primary.main, 0.2),
          fontSize: theme.typography.body2.fontSize
        },
        arrow: {
          color: lighten(theme.palette.primary.main, 0.2)
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          // border: '1px solid'
        },
        label: {
          ...theme.typography.body2,
          textOverflow: 'ellipsis',
          wordWrap: 'nowrap',
          overflow: 'hidden',
          paddingTop: 1,
          paddingBottom: 1
        },
        group: {
          marginLeft: 6
        },
        iconContainer: {
          width: '8px',
          color: '#777',

          '& svg': {
            fontSize: 12
          }
        },
        content: {
          '&.Mui-selected, &.Mui.focused, &:active': {
            backgroundColor: '#efefef !important'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '100%'
        },
        underline: {
          transition: 'opacity 0.5s ease',
          '&::after': {
            opacity: 0,
            transform: 'scaleX(1)'
          },
          [`&.${inputClasses.focused}`]: {
            '&::after': {
              opacity: 1
            }
          }
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true
      },
      styleOverrides: {
        root: {
          transform: 'translate(0px, -5px) scale(0.85)',
          width: '117%',
          lineHeight: '1rem',
          top: 3
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundImage: isDark
            ? 'linear-gradient(to left, #18181a, #18181a)'
            : undefined,
          boxShadow: 'none'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          '& svg': {
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation1:
          theme.palette.mode === 'dark'
            ? {
                boxShadow: '0 0 0 1px rgba(255, 255, 255, 0.05)'
              }
            : {},
        rounded: {
          borderRadius: 8
        }
      }
    }
  }
  return res
}

const makeTheme = (mode: 'light' | 'dark') => {
  const theme = createTheme({
    palette: mode === 'light' ? lightPalette : darkPalette,
    shadows,
    typography,
    shape: {
      borderRadius: 8
    }
  })

  theme.components = makeOverrides(theme)

  return theme
}

export const lightTheme = makeTheme('light')
export const darkTheme = makeTheme('dark')

export default makeTheme
