import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { del } from 'src/client'
import { reset as resetStores } from 'src/stores/reset'

export type User = {
  user_id: number
  email: string
}

// eslint-disable-next-line import/prefer-default-export
export const useDeleteUser = () => {
  const client = useQueryClient()
  return useMutation(
    async ({
      userId,
      password
    }: {
      userId: User['user_id']
      password: string
    }) => {
      await del(`/users/${userId}?password=${password}`)
      client.clear()
      localStorage.clear()
      resetStores()
    }
  )
}

export const useCurrentUser = () =>
  useQuery<User>({
    queryKey: ['/users/me'],
    staleTime: Infinity
  })
