import {
  QueryClient,
  QueryFunctionContext,
  useQuery
} from '@tanstack/react-query'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { useMemo } from 'react'

import { del, post, put, fetch } from 'src/client'
import { useMainChart } from 'src/data/charts'
import { flattenTree } from 'src/utils/trees'

import { Account } from './accounts'
import { WUseQueryOptions } from './common'

const tagTypes = ['realEstate', ''] as const

export type Tag = {
  tag_id: number
  name: string
  children: Tag[]
  parent_id?: Tag['tag_id']
  type?: (typeof tagTypes)[number]
}

export type CreateTagOptions = Omit<Tag, 'tag_id'>

export const isRealEstate = (tag: Tag) => {
  return tag?.type === 'realEstate'
}

export const fetchTags = async (chartId: Account['account_id']) => {
  const tags = await fetch<Tag[]>(`/charts/${chartId}/tags`)
  return tags
}

export const useTags = <Output = Tag[]>(
  options?: WUseQueryOptions<Tag[], unknown, Output>
) => {
  const { data: chart } = useMainChart()
  const chartId = chart && chart.account_id
  return useQuery<Tag[], unknown, Output>({
    queryKey: [`/charts/${chartId}/tags`],
    queryFn: () => fetchTags(chartId!!),
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !!chartId,
    ...options
  })
}

const getTagId = (tag: Tag) => tag.tag_id
const flattenTags = (tags: Tag[]) =>
  flattenTree(tags, getTagId)
    .filter((v) => v.parents.length > 0)
    .map((v) => ({
      value: v.value,
      parents: v.parents.slice(1)
    }))

export const createTag = async (data: CreateTagOptions) => {
  const res = await post<number>(`/tags/${data.parent_id}`, data)
  return res
}

export type CreateTagData = Tag & {
  parent_id: NonNullable<Tag['parent_id']>
}

export const updateTag = async (data: Tag) => {
  const res = await put(`/tags/${data.tag_id}`, data)
  return res
}

export const deleteTag = (tagId: Tag['tag_id']) => {
  return del(`/tags/${tagId}`)
}

export const useFlatTags = (
  options?: WUseQueryOptions<Tag[], unknown, Tag[]>
) => {
  const { data: tags, ...rest } = useTags(options)

  const flatTags = useMemo(() => {
    if (!tags) {
      return null
    }
    return flattenTags(tags)
  }, [tags])
  return { data: flatTags, ...rest }
}

export const useTagsById = (
  options?: WUseQueryOptions<Tag[], unknown, Tag[]>
) => {
  const { data: tags, ...rest } = useFlatTags(options)
  const tagsById = useMemo(() => {
    if (!tags) {
      return null
    }
    return mapValues(
      keyBy(tags, (flatTag) => getTagId(flatTag.value)),
      (flatTag) => flatTag.value
    )
  }, [tags])
  return {
    data: tagsById,
    ...rest
  }
}

export const isTag = (item: any): item is Tag => {
  return typeof item.tag_id !== 'undefined'
}

export const invalidateTags = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({
    predicate: (query: QueryFunctionContext) => {
      const { queryKey } = query
      return (
        typeof queryKey?.[0] === 'string' &&
        !!queryKey[0].match(/\/charts\/.*\/tags/)
      )
    }
  })
}
