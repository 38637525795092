import { Alert } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MUILink from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import React, { FormEventHandler, useCallback } from 'react'

import bridgeLogo from 'src/assets/bridge-logo.png'
import powensLogo from 'src/assets/powens-logo.png'
import { put } from 'src/client'
import EnhancedDialogTitle from 'src/components/EnhancedDialogTitle'
import Stack from 'src/components/Stack'
import { Backend } from 'src/data/backends'
import flag from 'src/flags'
import { useModals } from 'src/modals'
import * as bridge from 'src/utils/bridge'
import { useEncrypt } from 'src/utils/crypto'
import * as powens from 'src/utils/powens'

type BackendPasswordUpdateDialogProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  onCancel: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  onSuccess?: () => void
  backend: Backend
  // eslint-disable-next-line react/no-unused-prop-types
  open?: boolean
  reconnect?: boolean

  // This is propagated to BI which will send it back to us
  // on success
  callbackState?: string
} & Omit<DialogProps, 'open'>

const BackendPasswordUpdateDialog = ({
  onCancel,
  onSuccess,
  backend,
  open = true,
  ...rest
}: BackendPasswordUpdateDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const { encrypt, isLoading } = useEncrypt()
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (ev) => {
    ev.preventDefault()
    if (isLoading) {
      return
    }
    try {
      const { password } = Object.fromEntries(
        new FormData(ev.currentTarget).entries()
      )
      const encryptedPassword = await encrypt(password as string)
      await put(`/charts/${backend.chart_id}/backends/${backend.backend_id}`, {
        bank_password: encryptedPassword
      })
      enqueueSnackbar('Mise à jour du mot de passe effectuée', {
        variant: 'success'
      })
      onSuccess?.()
    } catch (e) {
      enqueueSnackbar(
        'Erreur lors de la mise à jour du mot de passe chez Winancial',
        {
          variant: 'error'
        }
      )
    }
  }

  return (
    <Dialog open={open} onClose={onCancel} {...rest}>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        data-lpignore="true" // This form should be ignored by LastPass
      >
        <DialogTitle>Mettre à jour le mot de passe</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Vous allez mettre à jour le mot de passe de {backend.backend_name}.
          </Typography>
          <Box mt={2} mb={2}>
            <TextField label="Mot de passe" name="password" type="password" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Annuler</Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            Mettre à jour
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const vendorByBankName = {
  'budget-insight': powens,
  bridge
}

export const BackendEditDialog = ({
  backend,
  reconnect,
  callbackState,
  ...dialogProps
}: BackendPasswordUpdateDialogProps) => {
  const { backend_id: backendId } = backend
  const usingBridge = backend.bank_name === 'bridge'
  const openReconnectWebview = useMutation(async () => {
    const bankName = backend.bank_name
    if (bankName !== 'bridge' && bankName !== 'budget-insight') {
      // Unsupported bank
      return
    }
    const vendorModule = vendorByBankName[bankName]
    await vendorModule.redirectToManageWebview({
      connectionId: backend.bank_password, // the connection id is in the bank password for BI & Bridge backends
      backendId,
      reconnect,
      callbackState
    })
  })
  const { closeModal } = useModals()
  const handleClose = useCallback(() => {
    closeModal('BackendPasswordUpdate')
    closeModal('BackendEdit')
  }, [])
  return (
    <Dialog open onClose={handleClose} {...dialogProps}>
      <EnhancedDialogTitle onClose={handleClose}>
        Gestion de la connexion
      </EnhancedDialogTitle>
      <DialogContent>
        <Stack spacing={3} direction="column" alignItems="center">
          <Alert severity="info">
            Pour modifier le mot de passe et gérer les comptes téléchargés, vous
            allez être redirigé vers notre partenaire <strong>Powens</strong>.
            <br />
            Après modification, vos comptes seront automatiquement synchronisés,
            cela peut prendre quelques minutes.
          </Alert>
          {usingBridge ? (
            <img
              width={193}
              height={50}
              src={bridgeLogo}
              alt="Logo de Bridge"
            />
          ) : (
            <img
              width={193}
              height={40}
              src={powensLogo}
              alt="Logo de Powens"
            />
          )}
          <Typography variant="caption">
            {usingBridge ? 'Bridge' : 'Powens'} est notre partenaire agréé par
            l&apos;
            <strong>Autorité de Contrôle et de Régulation Prudentielle</strong>,
            chargé du téléchargement de vos opérations bancaires depuis le site
            des banques.
            <br />
            Vous pouvez en savoir plus sur{' '}
            <MUILink
              color="primary"
              target="_blank"
              underline="always"
              rel="noreferrer"
              href="https://www.winancial.com/academy/connexion-aux-banques#BI"
            >
              notre article dédié
            </MUILink>
            .
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            openReconnectWebview.mutate()
          }}
        >
          Gérer la connexion
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Combined = (props: BackendPasswordUpdateDialogProps) => {
  const { backend } = props
  return (flag('newBackendFlow') && backend.bank_name === 'budget-insight') ||
    backend.bank_name === 'bridge' ? (
    <BackendEditDialog {...props} />
  ) : (
    <BackendPasswordUpdateDialog {...props} />
  )
}

export default Combined
