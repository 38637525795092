import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
// import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

import { usePWAStore } from 'src/stores/pwa'

const PWARefreshCheck = () => {
  const [hasDeclined, setHasDeclined] = useState(false)
  const { needRefresh, updateSW, lastUpdateTry } = usePWAStore()
  //   const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    // if (!lastUpdateTry) {
    // }
    // enqueueSnackbar(`Vérification des MAJ... ${lastUpdateTry}`, {
    //   variant: 'info'
    // })
  }, [lastUpdateTry])
  if (needRefresh && !hasDeclined) {
    return (
      <Dialog open fullScreen>
        <DialogContent>
          Une nouvelle version de l&apos;application a été publiée, rechargez la
          page pour bénéficier de la mise à jour.
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setHasDeclined(true)}
          >
            Pas tout de suite
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => updateSW()}
          >
            Mettre à jour
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  return null
}

export default PWARefreshCheck
