import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ModalName, useModals } from './modals'

export default function ModalOpener({
  redirect,
  modal
}: {
  redirect?: string
  modal: ModalName
}) {
  const modals = useModals()
  const navigate = useNavigate()
  useEffect(() => {
    if (redirect) {
      navigate(redirect)
    }
    modals.openModal(modal)
  }, [])
  return null
}
