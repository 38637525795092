import { useState, useEffect } from 'react'

import flag from './flag'

type ActiveFlag =
  | 'welcomeTab'
  | 'guidedTours'
  | 'screenshots'
  | 'dnd'
  | 'bridge-backend'

export default function useFlag(name: ActiveFlag | (string & {})) {
  const [flagValue, setFlag] = useState(flag(name))
  useEffect(() => {
    const handleChange = (changed: string) => {
      if (changed === name) {
        setFlag(flag(name))
      }
    }
    flag.store.on('change', handleChange)
    return () => {
      flag.store.removeListener('change', handleChange)
    }
  }, [setFlag, name])
  return flagValue
}

export function useFlags() {
  const [flags, setFlags] = useState(flag.list())
  useEffect(() => {
    const handleChange = () => {
      setFlags(flag.list())
    }
    flag.store.on('change', handleChange)
    return () => {
      flag.store.removeListener('change', handleChange)
    }
  }, [setFlags])
  return flags
}
