import { useEffect, useState } from 'react'

const useDelayedInit = <T extends unknown>(
  init: T,
  next: T,
  timeout: number
) => {
  const [s, setS] = useState(init)
  useEffect(() => {
    const t = setTimeout(() => {
      setS(next)
    }, timeout)
    return () => clearTimeout(t)
  }, [])
  return s
}

export default useDelayedInit
