import createStoreHook from 'zustand'
import { persist } from 'zustand/middleware'
import createStore from 'zustand/vanilla'

import { useFlag } from 'src/flags'
import useIsMobile from 'src/useIsMobile'

import { reset } from './reset'

export const guidedTours = createStore<{
  completed: Record<string, boolean>
  setCompleted: (tourName: string, value?: boolean) => void
  reset: () => void
}>(
  persist(
    (set) => ({
      completed: {} as Record<string, boolean>,
      setCompleted: (tourName: string, value: boolean = true) =>
        set((state) => ({
          completed: { ...state.completed, [tourName]: value || true }
        })),
      reset: () => set({ completed: {} as Record<string, boolean> })
    }),
    {
      name: 'guidedTours',
      getStorage: () => localStorage
    }
  )
)

export const useGuidedToursStore = createStoreHook(guidedTours)
export const useGuidedTourStore = (
  tourName: string,
  { canDisplayOnMobile = false } = {}
) => {
  const isScreenshotting = useFlag('screenshots')
  const isMobile = useIsMobile()
  return useGuidedToursStore((state) => ({
    completed:
      (isMobile && !canDisplayOnMobile) || isScreenshotting
        ? true
        : state.completed?.[tourName],
    setCompleted: (value?: boolean) => state.setCompleted(tourName, value),
    reset
  }))
}
