// @ts-ignore
// import { registerSW } from 'virtual:pwa-register'
import createStoreHook from 'zustand'
import createStore from 'zustand/vanilla'

// const intervalMS = 1 * 60 * 1000

let updateSW: () => void

export type PWAState = {
  needRefresh: boolean
  setNeedRefresh: (v: boolean) => void
  updateSW: () => void
  lastUpdateTry: Date | undefined
}

export const pwaStore = createStore<PWAState>((set) => ({
  needRefresh: false,
  lastUpdateTry: undefined as Date | undefined,
  setNeedRefresh: (needRefresh: boolean) => set({ needRefresh }),
  updateSW: () => updateSW?.()
}))

// updateSW = registerSW({
//   onNeedRefresh() {
//     pwaStore.setState({ needRefresh: true })
//   },
//   onOfflineReady() {},
//   onRegistered(r: { update: () => void } | undefined) {
//     if (!r) {
//       return
//     }
//     setInterval(() => {
//       pwaStore.setState({ lastUpdateTry: new Date() })
//       r.update()
//     }, intervalMS)
//   }
// })

export const usePWAStore = createStoreHook(pwaStore)
