/**
 * Originally from https://github.com/deema089786/react-modal-controller
 */

import { Backdrop, CircularProgress, Slide, SlideProps } from '@mui/material'
import React, {
  FunctionComponent,
  Suspense,
  useContext,
  useMemo,
  useState
} from 'react'

import useIsMobile from 'src/useIsMobile'
import { gtagEvent } from 'src/utils/gtag'

export interface Modals {
  addModal(key: string, modal: FunctionComponent<any>): void
  openModal(key: string, props?: { [key: string]: any }): void
  closeModal(key?: string): void
}

const initialState: Modals = {
  closeModal: () => {
    throw new Error(
      'Modal controller methods are only callable inside the ModalsContext, ensure that the component using the useModals hook in inside a ModalsContext.'
    )
  },
  openModal: () => {
    throw new Error(
      'Modal controller methods are only callable inside the ModalsContext, ensure that the component using the useModals hook in inside a ModalsContext.'
    )
  },
  addModal: () => {
    throw new Error(
      'Modal controller methods are only callable inside the ModalsContext, ensure that the component using the useModals hook in inside a ModalsContext.'
    )
  }
}

type ModalRecord = Record<string, React.ComponentType<any>>
type UseModals<ModalsType extends ModalRecord> = () => {
  openModal: <T extends Exclude<keyof ModalsType, number | symbol>>(
    name: T,
    options?: Omit<React.ComponentProps<ModalsType[T]>, 'open'>
  ) => void
  closeModal: (name: Exclude<keyof ModalsType, number | symbol>) => void
}

export const ModalsContext = React.createContext(initialState)
export const makeUseModals =
  <T extends ModalRecord>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _modals: T
  ): UseModals<T> =>
  () => {
    return useContext(ModalsContext)
  }

interface Props {
  children: JSX.Element | JSX.Element[]
  initialModals?: { [key: string]: FunctionComponent<any> }
}

const MobileTransition = React.forwardRef((props: SlideProps, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

export const ModalsProvider: React.FC<Props> = ({
  children,
  initialModals = {}
}: Props) => {
  const [modals, setModals] = useState<{
    [key: string]: FunctionComponent<any>
  }>(initialModals)
  const [modal, setModal] = useState<
    {
      key: string
      Component: FunctionComponent<any>
      props: { [key: string]: any }
      toRemove?: boolean
    }[]
  >([])

  const addModal: Modals['addModal'] = (key, ModalComponent) => {
    setModals((state) => ({ ...state, [key]: ModalComponent }))
  }

  const openModal: Modals['openModal'] = (key, props = {}) => {
    gtagEvent('open_modal', { name: key })
    setModal((state) => [
      ...state,
      {
        key,
        Component: modals[key],
        props
      }
    ])
  }

  const closeModal: Modals['closeModal'] = (key?: string) => {
    gtagEvent('close_modal', { name: key })

    if (key === undefined) {
      setModal([])
    } else {
      setModal((state) =>
        state.map((item) =>
          item.key === key ? { ...item, toRemove: true } : item
        )
      )
      setTimeout(() => {
        setModal((state) => state.filter((item) => !item.toRemove))
      }, 500)
    }
  }

  const ctxValue = useMemo(
    () => ({
      addModal,
      openModal,
      closeModal
    }),
    [openModal, closeModal, addModal]
  )

  const isMobile = useIsMobile()

  return (
    <ModalsContext.Provider value={ctxValue}>
      <Suspense
        fallback={
          <Backdrop open>
            <CircularProgress />
          </Backdrop>
        }
      >
        {modal.map((item) => (
          <item.Component
            {...item.props}
            open={item.toRemove !== true}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? MobileTransition : undefined}
            key={item.key}
          />
        ))}
      </Suspense>

      {children}
    </ModalsContext.Provider>
  )
}
