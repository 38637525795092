import { useTheme } from '@mui/material'
import React from 'react'
import { GlobalStyles } from 'tss-react'

const CustomGlobalStyles = () => {
  const theme = useTheme()
  return (
    <GlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          height: '100%',
          width: '100%',

          // Used in contexts where Typography is not there, for example graphs
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
          ].join(','),
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.paper
              : 'white'
        },
        body: {
          backgroundColor: '#f4f6f8',
          height: '100%',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            backgroundColor: 'white'
          }
        },
        a: {
          textDecoration: 'none'
        },
        '#root': {
          height: '100%',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            height: 'auto',
            minHeight: '100vh',
            background: theme.palette.background.paper,

            '&.hasNavBar': {
              // Nav bar height
              marginBottom: 64
            },

            WebkitTouchCallout: 'none' /* iOS Safari */,
            WebkitUserSelect: 'none' /* Safari */,
            KhtmlUserSelect: 'none' /* Konqueror HTML */,
            MozUserSelect: 'none' /* Old versions of Firefox */,
            MsUserSelect: 'none' /* Internet Explorer/Edge */,
            userSelect: 'none'
          }
        },

        // Recharts
        '.recharts-legend-item': { fontSize: '0.75rem' },
        '.recharts-cartesian-axis-tick': { fontSize: '0.75rem' },
        '.recharts-tooltip-wrapper:focus': {
          outline: 0
        },

        // Crisp

        '#crisp-chatbox [aria-label="Ouvrir le chat"], #crisp-chatbox [aria-label="Fermer le chat"]':
          {
            bottom: '20px !important',
            [theme.breakpoints.down('sm')]: {
              bottom: '70px !important'
            }
          }
      }}
    />
  )
}

export default CustomGlobalStyles
