import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { refreshBackend, renameOldConnection } from 'src/data/backends'
import { useMainChart } from 'src/data/charts'
import { useModals } from 'src/modals'
import * as bridge from 'src/utils/bridge'
import { gtagEvent } from 'src/utils/gtag'
import * as powens from 'src/utils/powens'

const getOldBackendIdFromURL = (url: URL) => {
  const stateParam = url.searchParams.get('state') // used by budget-insight
  const contextParam = url.searchParams.get('context') // used by bridge
  if (stateParam) {
    return stateParam
      ? (JSON.parse(stateParam) as powens.BIWebviewState)?.oldBackendId
      : undefined
  } else if (contextParam) {
    return Number(contextParam)
  }
  return undefined
}

const useHandleBudgetInsightCallbackURL = () => {
  const { data: chart } = useMainChart()
  const queryClient = useQueryClient()
  const { openModal } = useModals()

  const navigate = useNavigate()
  useEffect(() => {
    if (!chart) {
      return
    }
    const run = async () => {
      const biCheckResult = await powens.checkURLToSaveBackend(
        chart?.account_id,
        window.location.href
      )
      const bridgeCheckResult = await bridge.checkURLToSaveBackend(
        chart?.account_id,
        window.location.href
      )
      const checkResult = biCheckResult || bridgeCheckResult
      // Remove connectionId from URL
      queryClient.invalidateQueries(['backends'])
      const currentURL = new URL(window.location.href)
      const oldBackendId = getOldBackendIdFromURL(currentURL)
      if (checkResult?.status === 'created') {
        gtagEvent('vendor_connection_callback', {
          type: 'created'
        })
        const createdBackendId = checkResult.backendId
        console.log('Will clean URL', currentURL)
        window.history.replaceState(null, '', '/')
        if (oldBackendId) {
          await renameOldConnection(chart.account_id, oldBackendId)
          queryClient.invalidateQueries(['backends'])
        }
        navigate(`/app/settings/backends/${createdBackendId}`)
        openModal('ConfigureBackend', {
          backendId: createdBackendId,
          oldBackendId
        })
      } else if (checkResult?.status === 'existing') {
        console.log('Asking to refresh backend')
        gtagEvent('vendor_connection_callback', {
          type: 'existing'
        })
        console.log('Will clean URL', currentURL)
        window.history.replaceState(null, '', '/')
        navigate(`/app/settings/backends/${checkResult.backendId}`)
        await refreshBackend(chart.account_id, checkResult.backendId)
        await queryClient.invalidateQueries(['backends'])
      }
    }
    run()
  }, [chart, window.location])
}
/**
 * We need to have a fake component so that we can run the hook
 * in the context of the query client
 */

const UrlCallbackCheck = () => {
  useHandleBudgetInsightCallbackURL()
  return null
}

export default UrlCallbackCheck
