import { format } from 'date-fns'
import { pickBy } from 'lodash'

import { post } from 'src/client'
import { Account } from 'src/data/accounts'
import { Backend, fetchBackends } from 'src/data/backends'
import { getAppAbsoluteURL } from 'src/utils/url'

import truthy from './truthy'

const biEndpoint = 'https://winancial.biapi.pro/2.0'

const getBIParams = () => ({
  client_id: '92060074',
  redirect_uri: getAppAbsoluteURL('/app/settings')
})

type TokenResp = {
  code: string
  type: 'temporary'
  access: 'standard'
  expires_in: number
}

/**
 * Redirects to Powens webview with potentially a
 * temporary token generated from the permanent token of
 * the user on the server.
 */
export const redirectToWebview = async ({
  state
}: {
  state?: string
} = {}) => {
  const tokenResp = await post<TokenResp | null>('/users/bi/temporaryToken')
  const biParams = getBIParams()
  window.location.assign(
    `${biEndpoint}/auth/webview/fr/connect?${new URLSearchParams(
      pickBy(
        {
          ...biParams,
          code: tokenResp?.code || '',
          state
        },
        Boolean
      ) as Record<string, string>
    )}`
  )
}

/**
 * Redirects to Powens manage/reconnect webview
 * Generates a temporary token from the user
 */
export const redirectToManageWebview = async ({
  connectionId,
  backendId,
  reconnect,
  callbackState
}: {
  connectionId?: string
  backendId: Backend['backend_id']
  reconnect?: boolean
  callbackState?: string
}) => {
  const tokenResp = await post<TokenResp>('/users/bi/temporaryToken')
  const redirectUri = getAppAbsoluteURL(`/app/settings/backends/${backendId}`)
  const biParams = getBIParams()
  window.location.assign(
    `${biEndpoint}/auth/webview/fr/${
      reconnect ? 'reconnect' : 'manage'
    }?${new URLSearchParams(
      pickBy(
        {
          ...biParams,
          code: tokenResp.code,
          connection_id: connectionId,
          redirect_uri: redirectUri,
          state: callbackState
        },
        (x) => typeof x !== 'undefined'
      ) as Record<string, string>
    )}`
  )
}

type BITempToken = string

const createBackend = async (
  chartId: Account['account_id'],
  connectionId: string,
  // Temporary code that we need to exchange against permanent token
  // Only in the case where the user does not have an BI account yet
  bank_login?: BITempToken | (string & {})
): Promise<Backend['backend_id']> => {
  const newBackendId = await post<string>(
    `/charts/${chartId}/backends`,
    pickBy(
      {
        // bank_login = temporary token will be exchanged against permanent token
        // and needs to be readable by backend, thus it is not encrypted
        bank_login,
        bank_login2: '',
        bank_password: connectionId,
        bank_name: 'budget-insight',
        next_running_date: format(new Date(), 'yyyy-MM-dd HH:mm')
      },
      truthy
    )
  )
  return parseInt(newBackendId, 10)
}

export const checkURLToSaveBackend = async (
  chartId: Account['account_id'],
  url: string
): Promise<
  | {
      status: 'created' | 'existing'
      backendId: number
    }
  | undefined
> => {
  const params = new URL(url).searchParams
  const connectionId = params.get('connection_id')
  if (connectionId) {
    const code = params.get('code')
    const backends = await fetchBackends(chartId)
    const existingBackend = backends.find(
      (b) => b.bank_password === connectionId
    )
    if (existingBackend) {
      console.log('Already existing backend', existingBackend)
      return {
        status: 'existing',
        backendId: existingBackend.backend_id
      }
    } else {
      return {
        status: 'created',
        backendId: await createBackend(chartId, connectionId, code || undefined)
      }
    }
  }
  const backendId = params.get('backend_id')
  if (backendId) {
    return { status: 'existing', backendId: parseInt(backendId, 10) }
  }
  return undefined
}

export type BIWebviewState =
  | {
      oldBackendId: number
    }
  | undefined
