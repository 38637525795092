import { Delete as DeleteIcon } from '@mui/icons-material'
import DatePicker from '@mui/lab/DesktopDatePicker'
import {
  Box,
  BoxProps,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Link as MUILink,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Alert,
  SxProps,
  Theme,
  DialogProps
} from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format, subMonths } from 'date-fns'
import { groupBy, omit } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import acprLogo from 'src/assets/acpr-logo.svg'
import bridgeLogo from 'src/assets/bridge-logo.png'
import powensLogo from 'src/assets/powens-logo.png'
import { makeTreeStateKey } from 'src/components/TabsAccountsTree/useAccountsTreeState'
import {
  Account,
  AccountWithName,
  createAccount,
  useAccounts,
  useAccountsById,
  useDemoAccounts
} from 'src/data/accounts'
import {
  Backend,
  BackendAccount,
  deleteBackend,
  ensureNoToDeletePrefix,
  refreshBackends,
  saveBackend,
  useBackends
} from 'src/data/backends'
import { useMainChart } from 'src/data/charts'
import { useDeleteDemoData } from 'src/data/onboarding'
import { useFlag } from 'src/flags'
import { useModals } from 'src/modals'
import useIsMobile from 'src/useIsMobile'
import * as bridge from 'src/utils/bridge'
import { withStyles } from 'src/utils/makeStyles'
import * as powens from 'src/utils/powens'
import sleep from 'src/utils/sleep'
import useEvent from 'src/utils/useEvent'

import AccountIcon from './AccountIcon'
import EnhancedDialogTitle from './EnhancedDialogTitle'
import IntentButton from './IntentButton'
import LoadingButton from './LoadingButton'
import Stack from './Stack'
import useIntervalWhen from './useIntervalWhen'

const AddStepContent = ({
  backendId,
  oldBackendId,
  onNextStep
}: {
  backendId?: Backend['backend_id']
  oldBackendId?: Backend['backend_id']
  onNextStep: () => void
}) => {
  const demoAccounts = useDemoAccounts()
  const deleteDemoDataMutation = useDeleteDemoData()

  const usingBridge = useFlag('bridge-backend')

  const { enqueueSnackbar } = useSnackbar()

  const redirectMutation = useMutation(
    async () => {
      if (usingBridge) {
        await bridge.redirectToWebview({
          context: oldBackendId ? `${oldBackendId}` : ''
        })
      } else {
        await powens.redirectToWebview({
          state: JSON.stringify({ oldBackendId })
        })
      }
    },
    {
      onSuccess: () => {
        enqueueSnackbar(
          'Vous allez être redirigé(e) vers la connexion bancaire'
        )
      },
      onError: () => {
        enqueueSnackbar('Il y a eu un soucis, veuillez réessayer plus tard.', {
          variant: 'error'
        })
      }
    }
  )

  const handleConnectBank:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined = async () => {
    if (demoAccounts && demoAccounts.length > 0) {
      await deleteDemoDataMutation.mutateAsync()
    }
    await redirectMutation.mutateAsync()
  }
  if (backendId) {
    return (
      <>
        <Alert severity="success">
          Connexion créée, plus que quelques étapes avant de télécharger vos
          opérations.
        </Alert>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onNextStep()}
          >
            Continuer
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Stack spacing={2}>
        <Alert severity="info">
          Pour télécharger les opérations et les comptes bancaires, vous allez
          être redirigé(e) chez notre partenaire,{' '}
          {usingBridge ? (
            <strong>l&apos;entreprise française Bridge</strong>
          ) : (
            <strong>
              l&apos;entreprise française Powens (anciennement Budget-Insight)
            </strong>
          )}
          .
          <br />
          <Box
            sx={{
              listStyleType: 'square',
              margin: '1rem 1rem',
              paddingLeft: '1rem',
              'li + li': {
                marginTop: '0.5rem'
              }
            }}
            component="ul"
          >
            <li>
              {usingBridge ? 'Bridge' : 'Powens'} est agréée par l&apos;Autorité
              de Contrôle Prudentielle et de Résolution de la{' '}
              <strong>Banque de France</strong>. L&apos;ACPR est en charge de la
              protection des clients des secteurs de la banque et de
              l&apos;assurance.
            </li>
            <li>
              Nous leur avons confié la connexion et la synchronisation des
              comptes bancaires pour{' '}
              <strong>plus de fiabilité et de sécurité</strong>.
            </li>
            <li>
              Nous n&apos;accédons pas à votre compte bancaire, seulement à vos
              transactions en lecture seule.
              <br />
              <strong>
                Aucun mouvement d&apos;argent n&apos;est possible dans
                Winancial.
              </strong>
            </li>
          </Box>
          Vous pouvez en savoir plus sur{' '}
          <MUILink
            color="primary"
            target="_blank"
            underline="always"
            rel="noreferrer"
            href="https://www.winancial.com/docs/S%C3%A9curit%C3%A9/Connexion_bancaire"
          >
            notre article dédié
          </MUILink>
          .<br />
          <MUILink
            color="primary"
            target="_blank"
            underline="always"
            rel="noreferrer"
            href="https://acpr.banque-france.fr/page-sommaire/missions"
          >
            En savoir plus sur l&apos;ACPR sur leur site.
          </MUILink>
        </Alert>
        <Box textAlign="center">
          {demoAccounts && demoAccounts.length > 0 ? (
            <Alert severity="info" sx={{ mb: 2 }}>
              Les données de test seront supprimées automatiquement.
            </Alert>
          ) : null}
          <LoadingButton
            onClick={handleConnectBank}
            variant="contained"
            color="primary"
            size="large"
            loading={
              deleteDemoDataMutation.isLoading || redirectMutation.isLoading
            }
          >
            Connecter ma banque
          </LoadingButton>
        </Box>
        <div>
          <Box mt={4}>
            <Stack
              direction="row"
              spacing={6}
              justifyContent="center"
              alignItems="center"
            >
              {usingBridge ? (
                <img
                  width={145}
                  height={37}
                  src={bridgeLogo}
                  alt="Logo de Powens"
                  style={{ filter: 'grayscale(1)' }}
                />
              ) : (
                <img
                  width={145}
                  height={30}
                  src={powensLogo}
                  alt="Logo de Powens"
                  style={{ filter: 'grayscale(1)' }}
                />
              )}
              <img
                height={30}
                src={acprLogo}
                alt="Logo de Budget Insight"
                style={{ filter: 'grayscale(1)' }}
              />
            </Stack>
          </Box>
        </div>
      </Stack>
    </>
  )
}

type ConfigureStepNextStepOptions = {
  hasCreatedAccounts: boolean
}

const SuccessChip = withStyles(Chip, (theme) => ({
  root: {
    backgroundColor: theme.palette.success.dark,
    color: 'white'
  }
}))

const AccountLabel = ({
  account,
  ...props
}: { account: Account } & BoxProps) => {
  return (
    <Box display="flex" {...props} alignItems="center">
      <AccountIcon account={account} sx={{ mr: 1 }} />
      <Typography variant="body1">{account.name}</Typography>
      <Box sx={{ ml: 1 }}>
        {account.account_id === -1 ? (
          <SuccessChip size="small" label="À créer" />
        ) : (
          <Chip size="small" label="Existant" />
        )}
      </Box>
    </Box>
  )
}

const AccountStructure = ({
  root,
  sx
}: {
  root: Account
  sx: SxProps<Theme>
}) => {
  return (
    <Stack spacing={0.5} sx={sx}>
      <AccountLabel account={root} />
      {root.children.map((c) => {
        return <AccountLabel account={c} ml={1} />
      })}
    </Stack>
  )
}

const ConfigureStepContent = ({
  backend,
  onNextStep,
  onlyAuto,
  mx
}: {
  backend?: Backend
  onNextStep: (options: ConfigureStepNextStepOptions) => void
  /** Used when configuring missing backend */
  onlyAuto?: boolean
  mx?: number
}) => {
  const queryClient = useQueryClient()
  const isFirstSync = !backend?.last_running_date
  const { data: rootAccounts } = useAccounts({ enabled: false })
  const { data: accountsById } = useAccountsById({ enabled: false })

  const refreshBackendsMutation = useMutation(
    ({ chartId }: { chartId: Account['account_id'] }) =>
      refreshBackends(chartId),
    {
      onSuccess: () => queryClient.invalidateQueries(['backends'])
    }
  )
  useEffect(() => {
    const run = async () => {
      if (
        backend?.status === 'Ready_For_Import' &&
        !backend?.next_running_date &&
        backend.chart_id &&
        refreshBackendsMutation.isIdle
      ) {
        refreshBackendsMutation.mutate({ chartId: backend.chart_id })
      }
    }
    run()
  }, [backend?.status, backend?.next_running_date, backend?.chart_id])

  useIntervalWhen(
    () => {
      queryClient.invalidateQueries(['backends'])
    },
    1500,
    isFirstSync
  )

  useEffect(() => {
    return () => {
      localStorage.removeItem(makeTreeStateKey('configureBackend-importTree'))
    }
  }, [])

  const createAutoAccountsMutation = useMutation(
    async (rootAccount: Account) => {
      if (!rootAccounts?.assetRoot?.account_id || !backend) {
        return
      }
      const backendAccountLinks: Record<
        BackendAccount['backend_account_id'],
        Account['account_id']
      > = {}
      const createAccountRec = async (
        item: AccountWithName & {
          backend_account_id?: number
        },
        parentId: Account['account_id']
      ) => {
        // When the tree structure is created, the backend account id that should
        // be linked with the created account is stored in backend_account_id, see below.
        const backendAccountId = item.backend_account_id
        const parentAccountId =
          item.account_id === -1
            ? await createAccount({
                ...omit(item, 'account_id', 'backend_account_id'),
                parent_id: parentId,
                isTypeReadWrite: true,
                description: item.description || ''
              })
            : item.account_id
        if (backendAccountId) {
          backendAccountLinks[backendAccountId] = parentAccountId
        }
        if (item.children && item.children.length > 0) {
          await Promise.all(
            item.children.map((c) => {
              return createAccountRec(c as AccountWithName, parentAccountId)
            })
          )
        }
      }
      await createAccountRec(
        rootAccount as AccountWithName,
        rootAccounts.assetRoot?.account_id
      )
      await saveBackend({
        ...backend,
        accounts: backend.accounts.map((acc) => ({
          ...acc,
          status: 'Ready_For_Import',
          winancial_account_id: backendAccountLinks[acc.backend_account_id]
        }))
      })
      queryClient.invalidateQueries([`/charts/${backend.chart_id}/accounts`])
      queryClient.invalidateQueries([`backends`])
      onNextStep({ hasCreatedAccounts: true })
    }
  )

  const { true: mappedAccounts = [], false: unmappedAccounts = [] } =
    useMemo(() => {
      return groupBy(
        backend?.accounts || [],
        (ba) => !!accountsById?.[ba.winancial_account_id]
      )
    }, [backend, accountsById])

  const automaticAccountStructure = useMemo(() => {
    if (!backend) {
      return
    }
    const parentId =
      mappedAccounts.length > 0
        ? accountsById?.[mappedAccounts[0].winancial_account_id].parent_id
        : -1
    return {
      account_id: parentId,
      name: backend.backend_name,
      parent_id: -1, // is replaced when creating the accounts
      type: 'Bank',
      children: backend.accounts
        .filter((ba) => !ba.winancial_account_id)
        .map((ba) => ({
          account_id: -1,
          // Will be replaced when creating accounts (see above). Is used only to
          // link the backend account with the created account.
          backend_account_id: ba.backend_account_id,
          parent_id: 0, // is replaced when creating the accounts
          name: ba.bank_account_label,
          type: 'Checking',
          children: []
        }))
    } as Account
  }, [backend])

  const { enqueueSnackbar } = useSnackbar()
  const handleClickCreateAutoAccounts = useEvent(() => {
    if (!automaticAccountStructure) {
      return
    }
    createAutoAccountsMutation.mutate(automaticAccountStructure, {
      onSuccess: () => {
        enqueueSnackbar('Les comptes manquants ont été créés', {
          variant: 'success'
        })
        if (backend?.chart_id) {
          refreshBackendsMutation.mutate({ chartId: backend?.chart_id })
        }
        onNextStep({ hasCreatedAccounts: true })
      }
    })
  })
  const isMobile = useIsMobile()

  if (!backend) {
    return (
      <>
        <Typography variant="body1">
          Attention, vous n&apos;avez pas créé de connexion à l&apos;étape
          précédente. Vous pouvez continuer si vous avez déjà fait cet étape.
        </Typography>
      </>
    )
  }

  return (
    <>
      {backend?.accounts.length > 0 ? (
        <Box mx={mx}>
          <Stack
            spacing={isMobile ? 2 : 0}
            direction={isMobile ? 'column' : 'row'}
          >
            {unmappedAccounts.length > 0 &&
            !createAutoAccountsMutation.isSuccess ? (
              <Stack
                display="flex"
                my={6}
                spacing={isMobile ? 4 : 0}
                justifyContent="center"
                alignItems={isMobile ? 'center' : undefined}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Box flexGrow={1} flexBasis={1} display="flex">
                  <Stack
                    spacing={3}
                    justifyContent="space-between"
                    alignItems={isMobile ? 'center' : undefined}
                  >
                    <Typography variant="body1">
                      <strong>Créér les comptes manquants</strong> dans
                      Winancial
                    </Typography>
                    {automaticAccountStructure ? (
                      <AccountStructure
                        sx={{ ml: 2 }}
                        root={automaticAccountStructure}
                      />
                    ) : null}
                    {!createAutoAccountsMutation.isSuccess ? (
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={createAutoAccountsMutation.isLoading}
                        onClick={handleClickCreateAutoAccounts}
                      >
                        Créer les comptes
                      </LoadingButton>
                    ) : null}
                    {createAutoAccountsMutation.isSuccess ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onNextStep({ hasCreatedAccounts: true })}
                      >
                        Continuer
                      </Button>
                    ) : null}
                  </Stack>
                </Box>
                {!onlyAuto ? (
                  <Box
                    flexGrow={0}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    px={8}
                  >
                    <Typography variant="h6">ou</Typography>
                  </Box>
                ) : null}
                {!onlyAuto ? (
                  <Box flexGrow={1} flexBasis={1} display="flex">
                    <Stack
                      justifyContent="space-between"
                      spacing={1}
                      alignItems={isMobile ? 'center' : undefined}
                      textAlign={isMobile ? 'center' : undefined}
                    >
                      <div>
                        <Typography variant="body1">
                          <strong>Réutiliser des comptes</strong> Winancial
                          existant
                        </Typography>
                        <Typography variant="caption">
                          Vous pouvez gérer cette affectation dans l&apos;onglet{' '}
                          <strong>Paramètres</strong>.
                        </Typography>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          onNextStep({ hasCreatedAccounts: false })
                        }
                      >
                        Réutiliser des comptes existants
                      </Button>
                    </Stack>
                  </Box>
                ) : null}
              </Stack>
            ) : null}
          </Stack>
        </Box>
      ) : (
        <Box textAlign="center">
          <CircularProgress size={16} />{' '}
          <Typography variant="body1" display="inline">
            Vos comptes se synchronisent...
          </Typography>
          <br />
          <Typography variant="body2">
            Cela devrait prendre moins d&apos;une minute.
          </Typography>
        </Box>
      )}
    </>
  )
}

const DeleteOldBackendStep = ({
  backendId,
  onNextStep
}: {
  backendId: Backend['backend_id']
  onNextStep: () => void
}) => {
  const { data: chart } = useMainChart({ enabled: false })
  const { data: backends } = useBackends({ enabled: false })
  const backend = backends?.find((ba) => ba.backend_id === backendId)
  const removeMutation = useMutation(
    async ({ chartId }: { chartId: Account['account_id'] }) => {
      await deleteBackend(chartId, backendId)
    },
    {
      onSuccess: () => onNextStep()
    }
  )
  const handleClick = useCallback(() => {
    if (!chart?.account_id) {
      return
    }
    removeMutation.mutate({
      chartId: chart?.account_id
    })
  }, [chart?.account_id, backendId])

  return (
    <Stack spacing={2} alignItems="center" sx={{ textAlign: 'center' }}>
      {backend ? (
        <>
          <Typography variant="body1">
            Votre nouvelle connexion est maintenant prête 🎉 !<br />
            L&apos;ancienne connexion{' '}
            <strong>{ensureNoToDeletePrefix(backend.backend_name)}</strong> a
            été mise en pause et ne téléchargera plus d&apos;opérations.
            <br />
            Nous vous invitons à la <strong>supprimer</strong> sauf si vous
            voulez <strong>comparer l&apos;affectation</strong> des comptes
            entre la nouvelle et l&apos;ancienne connexion.
          </Typography>
          <Alert severity="success">
            Vos comptes et opérations seront bien sûr préservés dans les deux
            cas.
          </Alert>
        </>
      ) : (
        <Alert severity="success">L&apos;ancien compte a été supprimé.</Alert>
      )}
      <DialogActions>
        <Button variant="contained" onClick={() => onNextStep()}>
          Supprimer plus tard
        </Button>
        {!removeMutation.isSuccess && !removeMutation.isError && backend ? (
          <IntentButton
            variant="contained"
            intent="success"
            startIcon={<DeleteIcon />}
            loading={removeMutation.isLoading}
            onClick={handleClick}
          >
            Supprimer l&apos;ancienne connexion
          </IntentButton>
        ) : null}
      </DialogActions>
      <Typography variant="body1" color="error">
        {removeMutation.isError ? (
          <>Il y a eu un problème, veuillez réessayer plus tard.</>
        ) : null}
      </Typography>
    </Stack>
  )
}

const ConfigureSplitDateStep = ({
  backend,
  onNextStep,
  oldBackendId
}: {
  backend?: Backend
  onNextStep: () => void
  oldBackendId?: Backend['backend_id']
}) => {
  const [splitDate, setSplitDate] = useState<Date | null>(() =>
    oldBackendId ? new Date() : subMonths(new Date(), 3)
  )
  const saveSyncDateMutation = useMutation(
    async (splitDateToSave?: string) => {
      if (!backend) {
        return
      }
      if (!splitDateToSave) {
        onNextStep()
        return
      }
      await saveBackend({
        ...backend,
        bank_login2: splitDateToSave
      })
    },
    {
      onSuccess: () => {
        onNextStep()
      }
    }
  )

  const handleSaveSyncDate = useCallback(() => {
    if (!splitDate) {
      return
    }
    saveSyncDateMutation.mutate(format(splitDate, 'yyyy-MM-dd'))
  }, [splitDate])
  return (
    <>
      <Stack spacing={1} text-align="center">
        <Typography variant="body1">
          Indiquez ici la date de reprise des transactions de votre compte.
        </Typography>
        {oldBackendId ? (
          <Alert severity="warning">
            Pour éviter les doublons, nous vous conseillons d&apos;indiquer{' '}
            <strong>le jour suivant</strong> la date de la dernière opération
            téléchargée.
            <br />
            Si vous souhaitez vérifier la date de la dernière transaction,{' '}
            <strong>ne fermez pas cette fenêtre</strong> et ouvrez Winancial
            dans un nouvel onglet de votre navigateur.
          </Alert>
        ) : (
          <Alert severity="info">
            Nous téléchargerons toutes les opérations disponibles depuis cette
            date. Vous pourrez ainsi les <strong>catégoriser</strong> et voir
            ces transactions dans <strong>vos analyses</strong>. Nous vous
            conseillons de ne pas choisir une date trop loin dans le passé pour
            vous éviter trop de travail 😏.
            <br />
            Si vous changez d&apos;avis en voyant le nombre de transactions,
            vous pouvez supprimer votre compte bancaire dans l&apos;onglet{' '}
            <strong>Compte</strong>, votre connexion dans l&apos;onglet{' '}
            <strong>Paramètres</strong> et recommencer la synchronisation en
            choisissant une nouvelle date.
          </Alert>
        )}
        <DatePicker
          value={splitDate}
          renderInput={(props) => <TextField {...props} />}
          inputFormat="dd/MM/yyyy"
          onChange={(date) => {
            setSplitDate(date)
          }}
        />
        <Typography variant="caption" display="block">
          Pour information, la durée de l&apos;historique disponible dépend de
          chaque banque.
        </Typography>
      </Stack>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveSyncDate}
        >
          Continuer
        </Button>
      </DialogActions>
    </>
  )
}

type ConfigureBackendContext = {
  backend?: Backend
  oldBackendId?: Backend['backend_id']
}

const allSteps = [
  {
    type: 'ADD',
    label: 'Ajout',
    shouldShow: () => true
  },
  {
    type: 'SYNC_DATE',
    shouldShow: () => true,
    label: 'Date de reprise'
  },
  {
    type: 'MAPPING',
    label: 'Affectation aux comptes Winancial',
    shouldShow: () => true
  },
  {
    type: 'DELETE_OLD',
    shouldShow: (ctx: ConfigureBackendContext) => ctx.oldBackendId,
    label: "Suppression de l'ancienne connexion"
  },
  {
    type: 'END',
    label: 'Fin',
    shouldShow: () => true
  }
] as const

export type StepType = (typeof allSteps)[number]

const ConfigureBackendDialog = ({
  backendId: backendIdProp,
  oldBackendId,
  initialStep,
  ...props
}: {
  backendId?: Backend['backend_id']
  oldBackendId?: Backend['backend_id']
  initialStep?: StepType['type']
} & Omit<DialogProps, 'open'>) => {
  const [activeStep, setActiveStep] = useState<StepType>(
    initialStep
      ? () => allSteps.find((s) => s.type === initialStep) || allSteps[0]
      : allSteps[0]
  )
  const [backendId, setBackendId] = useState<Backend['backend_id'] | undefined>(
    backendIdProp
  )
  const { data: backends } = useBackends({
    staleTime: 0
  })
  const backend = backendId
    ? backends?.find((b) => b.backend_id === backendId)
    : undefined
  const { openModal, closeModal } = useModals()

  const steps = useMemo(() => {
    return allSteps.filter((step) => {
      return step.shouldShow({ backend, oldBackendId })
    })
  }, [backend, oldBackendId])

  const activeStepIndex = steps.findIndex((s) => s === activeStep)
  const goToNextStep = useCallback(() => {
    setActiveStep((currentActiveStep: StepType) => {
      const nextIndex = Math.min(
        steps.length - 1,
        steps.findIndex((x) => x === currentActiveStep) + 1
      )
      const nextStep = steps[nextIndex]
      return nextStep
    })
  }, [steps])

  useEffect(() => {
    if (backendIdProp && backendIdProp !== backendId) {
      setBackendId(backendIdProp)
    }
  }, [backendId, backendIdProp])

  const queryClient = useQueryClient()
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['entries'])
    }
  })
  const isMobile = useIsMobile()
  const [hasCreatedAccounts, setHasCreatedAccounts] = useState(false)

  return (
    <Dialog maxWidth="md" fullWidth {...props} open>
      <EnhancedDialogTitle onClose={() => closeModal('ConfigureBackend')}>
        {oldBackendId
          ? 'Mise à jour de connexion bancaire'
          : 'Ajout de connexion bancaire'}
      </EnhancedDialogTitle>
      <DialogContent>
        <Stepper
          sx={{
            mt: 0,
            mb: activeStep.type === 'ADD' ? 0 : 4,
            opacity: activeStep.type === 'ADD' ? 0 : 1
          }}
          activeStep={activeStepIndex}
        >
          {steps.map((step) => {
            const stepProps = {}
            const labelProps = {}
            const { label } = step
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box mt={2} mb={4} margin="auto" maxWidth={600}>
          {activeStep.type === 'ADD' ? (
            <AddStepContent
              backendId={backendId}
              oldBackendId={oldBackendId}
              onNextStep={goToNextStep}
            />
          ) : null}
          {activeStep.type === 'SYNC_DATE' ? (
            <ConfigureSplitDateStep
              backend={backend}
              oldBackendId={oldBackendId}
              onNextStep={() => {
                goToNextStep()
                if (backend) {
                  refreshBackends(backend?.chart_id)
                } else {
                  console.warn('No backend in configure split date step')
                }
              }}
            />
          ) : null}
          {activeStep.type === 'MAPPING' ? (
            <ConfigureStepContent
              mx={isMobile ? 0 : -14}
              backend={backend}
              onNextStep={({ hasCreatedAccounts: newHasCreatedAccounts }) => {
                goToNextStep()
                setHasCreatedAccounts(newHasCreatedAccounts)
                if (!oldBackendId && backend?.chart_id) {
                  refreshBackends(backend.chart_id)
                }
              }}
            />
          ) : null}
          {activeStep.type === 'DELETE_OLD' && oldBackendId ? (
            <DeleteOldBackendStep
              backendId={oldBackendId}
              onNextStep={goToNextStep}
            />
          ) : null}
          {activeStep.type === 'END' ? (
            <>
              <Alert sx={{ mb: 1 }} severity="success">
                La connexion à votre banque fonctionne maintenant.
                <br />
                {hasCreatedAccounts ? (
                  <>
                    Vos opérations vont être téléchargées. Quand le statut de la
                    connexion passe en <strong>Réussi</strong>, vous pourrez
                    voir vos opérations dans l&apos;onglet{' '}
                    <strong>Mes comptes</strong>.
                  </>
                ) : (
                  <>
                    Vous devez encore affecter les comptes Winancial à vos
                    comptes bancaires, puis cliquez sur{' '}
                    <strong>Tout synchroniser</strong> pour lancer le
                    téléchargement des opérations.
                  </>
                )}
              </Alert>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={async () => {
                    closeModal('ConfigureBackend')
                    await sleep(100)
                    openModal('ConfigureBackend')
                  }}
                >
                  Ajouter une autre banque
                </Button>
                {hasCreatedAccounts ? (
                  <Button
                    component={Link}
                    to="/app"
                    onClick={() => {
                      closeModal('ConfigureBackend')
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Voir mes comptes
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      closeModal('ConfigureBackend')
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Affecter mes comptes
                  </Button>
                )}
              </DialogActions>
            </>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ConfigureBackendDialog

export const ConfigureBackendMappingDialog = ({
  onClose,
  backend
}: {
  backend: Backend
  onClose: () => void
}) => {
  return (
    <Dialog open maxWidth="sm">
      <EnhancedDialogTitle onClose={onClose} />
      <DialogContent>
        <ConfigureStepContent onlyAuto backend={backend} onNextStep={onClose} />
      </DialogContent>
    </Dialog>
  )
}
