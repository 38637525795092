/* eslint-disable react-refresh/only-export-components */

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { backendsQuery } from 'src/data/backends'

import App from './App'
import ModalOpener from './ModalOpener'
import queryClient from './client'
import { budgetsQuery } from './data/budgets'
import { fetchMainChart } from './data/charts'
import { planQuery } from './data/plans'
import { schedulesQuery } from './data/schedules'
import lazyWithRetry from './utils/lazyWithRetry'

const MainLayout = lazyWithRetry(() => import('src/layouts/MainLayout'))

const AppLayout = lazyWithRetry(() => import('src/layouts/AppLayout'))

const LoginView = lazyWithRetry(() => import('src/views/auth/LoginView'))
const LostPasswordView = lazyWithRetry(
  () => import('src/views/auth/LostPasswordView')
)

const NewPasswordView = lazyWithRetry(
  () => import('src/views/auth/NewPasswordView')
)

const RegisterView = lazyWithRetry(() => import('src/views/auth/RegisterView'))
const NotFoundView = lazyWithRetry(
  () => import('src/views/errors/NotFoundView')
)

const BudgetView = lazyWithRetry(() => import('src/views/BudgetView'))
const SettingsView = lazyWithRetry(
  () => import('src/views/settings/SettingsView')
)
const SearchView = lazyWithRetry(
  () => import('src/views/AccountsView/SearchView')
)

const AccountsView = lazyWithRetry(() => import('src/views/AccountsView'))

const Subscription = lazyWithRetry(
  () => import('./views/settings/SettingsView/Subscription')
)

const Schedules = lazyWithRetry(
  () => import('./views/settings/SettingsView/Schedules')
)

const Backends = lazyWithRetry(
  () => import('./views/settings/SettingsView/Backends')
)

const RealEstate = lazyWithRetry(() => import('./views/RealEstate/RealEstate'))

const BudgetEditView = lazyWithRetry(() => import('src/views/BudgetEditView'))
const SynthesisView = lazyWithRetry(() => import('src/views/SynthesisView'))
const Playground = lazyWithRetry(() => import('src/views/Playground'))

export const isAccountsLocation = (
  location: ReturnType<typeof useLocation>
) => {
  return location.pathname.startsWith('/app/accounts')
}

export const isSynthesisLocation = (
  location: ReturnType<typeof useLocation>
) => {
  return location.pathname.startsWith('/app/synthesis')
}

export const routes = [
  {
    element: <App />,
    children: [
      {
        path: 'app/*',
        element: <AppLayout />,
        children: [
          {
            path: 'realestate',
            element: <RealEstate />,
            loader: () => import('src/views/RealEstate/RealEstate'),
            children: [
              {
                path: ':tagId',
                element: null
              },
              {
                path: '*',
                element: null
              }
            ]
          },
          {
            path: 'accounts',
            element: <AccountsView />,
            name: 'accounts',
            loader: () => import('src/views/AccountsView'),
            children: [
              {
                path: 'welcome',
                element: <ModalOpener redirect=".." modal="Welcome" />
              },
              {
                path: 'configureBackend',
                element: <ModalOpener redirect=".." modal="ConfigureBackend" />
              }
            ]
          },

          // Use only on mobile for useAutomaticAccountOpen to work well
          // Otherwise AccountsView is the same one between accounts route
          // and
          {
            path: 'search',
            element: <SearchView />,
            name: 'search'
          },

          {
            path: 'synthesis',
            element: <SynthesisView />,
            loader: () => import('src/views/SynthesisView')
          },
          {
            path: 'budget/:budgetId/edit',
            element: <BudgetEditView />,
            loader: () =>
              Promise.all([
                import('src/views/BudgetEditView'),
                fetchMainChart(queryClient).then((mainChart) =>
                  queryClient.prefetchQuery(budgetsQuery(mainChart.account_id))
                )
              ])
          },
          {
            path: 'budget',
            element: <BudgetView />,
            loader: () =>
              Promise.all([
                import('src/views/BudgetView'),
                fetchMainChart(queryClient).then((mainChart) =>
                  queryClient.prefetchQuery(budgetsQuery(mainChart.account_id))
                )
              ])
          },
          {
            path: 'settings/*',
            element: <SettingsView />,
            loader: () => import('src/views/settings/SettingsView'),
            children: [
              {
                path: 'backends',
                element: <Backends />,
                loader: () =>
                  Promise.all([
                    import('src/views/settings/SettingsView/Backends'),
                    fetchMainChart(queryClient).then((mainChart) =>
                      queryClient.prefetchQuery([
                        backendsQuery(mainChart.account_id)
                      ])
                    )
                  ]),
                children: [
                  { path: '', element: <div /> },
                  { path: ':backendId', element: <div /> }
                ]
              },
              {
                path: 'subscription',
                element: <Subscription />,
                loader: () => [
                  import('src/views/settings/SettingsView/Subscription'),
                  queryClient.prefetchQuery(planQuery({ includeExpired: true }))
                ]
              },
              { path: 'security', element: <Subscription /> },
              {
                path: 'schedules',
                element: <Schedules />,
                loader: () =>
                  Promise.all([
                    import('src/views/settings/SettingsView/Schedules'),
                    fetchMainChart(queryClient).then((mainChart) => {
                      return queryClient.prefetchQuery(
                        schedulesQuery(mainChart.account_id)
                      )
                    })
                  ])
              },
              { path: '*', element: <Navigate to="/app/settings/backends" /> }
            ]
          },
          { path: '', element: <Navigate to="/app/accounts" /> },
          {
            path: 'playground',
            element: <Playground />
          }
        ]
      },
      {
        path: '*',
        element: <MainLayout />,
        children: [
          { path: 'login', element: <LoginView /> },
          {
            path: 'register',
            element: <RegisterView />
          },
          {
            path: 'lostpassword',
            element: <LostPasswordView />
          },
          {
            path: 'newPassword',
            element: <NewPasswordView />
          },
          { path: '404', element: <NotFoundView /> },
          { path: '', element: <Navigate to="/app/accounts" /> },
          { path: '*', element: <Navigate to="/404" /> }
        ]
      }
    ]
  }
]

export default routes
